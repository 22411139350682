import React from "react";

const CustomPaginationUpdated = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  totalPages,
  totalLength,
  setDefaultPage
}) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <nav aria-label="Table navigation">
      <ul className="pagination justify-content-end">
        {/* <li>
          <div className="input-group">
            <div className="input-group-prepend">
              <label
                className="input-group-text"
                style={{ padding: "4.5px 8px" }}
              >
                Rows per page
              </label>
            </div>
            <select
              className="custom-select"
              style={{ padding: "4.5px 1.75rem 4.5px 1rem", height: "auto" }}
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
            >
              {[5, 10, 25, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </li> */}

        <li className={"page-item disabled"}>
          <a className="page-link">
            {startIndex + 1}-{endIndex} 0f {totalPages}
          </a>
        </li>

        <li
          className={page === 0 ? "page-item disabled" : "page-item"}
          onClick={() => {
            if (page === 0) return;

            onPageChange(page === 0 ? 0 : page - 1)
            let oldPage = page === 0 ? 0 : page - 1;
            console.log("Old PAGE:");
            console.log(oldPage);
            setDefaultPage(oldPage);
            }
          }   
          style={{ cursor: "pointer" }}
        >
          <a className="page-link" tabIndex="-1" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        {/* {items} */}
        <li
          className={
            totalLength < 100
              ? "page-item disabled"
              : "page-item"
          }
          onClick={() => {
            if(totalLength < 100) return;

            const lastPage = Math.ceil(totalPages / rowsPerPage); // Correct last page calculation
            const newPage = page >= lastPage ? page : page + 1;  // Ensure it doesn't go beyond last page          
            onPageChange(page === Math.ceil(totalPages / rowsPerPage) - 1 ? page : page + 1);
              // const newPage = Math.ceil(totalPages / rowsPerPage) - 1 ? page : page + 1;
              console.log("NEW PAGE:");
              console.log(newPage);
              setDefaultPage(newPage);
            }
          }
          style={{ cursor: "pointer" }}
        >
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default CustomPaginationUpdated;
