import React from "react";

//
import AppLogo from "../../assets/images/logo.png";
import BssLogo from "../../assets/images/bsslogo.png";
import { useAuthContext } from "context/auth.context";
import { formattedDate } from "utils/Helper";

const PaperHeader = ({ data }) => {
  const { user } = useAuthContext();
  const isEduUser = user.user_group_id == 1;
  const isBssUser = user.user_group_id == 2;
  const boardCheck = data?.paper_details[0]?.paper_board_type_id;
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const todayDate = new Date();
  // console.log("TADREES HEADER:", data);
  return (
    <div className="row clearfix" style={{ marginBottom: "30px" }}>
      <div
        className="col-2"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isEduUser && (
          <img
            src={AppLogo}
            alt="Logo"
            className="img-responsive logo"
            style={{ maxWidth: "100%" }}
          />
        )}
        {isBssUser && (
          <img
            src={BssLogo}
            alt="Logo"
            className="img-responsive logo"
            style={{ maxWidth: "100%" }}
          />
        )}
      </div>
      <div
        className="col-8"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isEduUser && (
          <h5 style={{ fontWeight: "900", marginBottom: 0 }}>THE EDUCATORS</h5>
        )}
        {isBssUser && (
          <h5
            style={{
              fontWeight: "900",
              marginBottom: 0,
              fontFamily: "old-english-text-mt-regular, sans-serif",
            }}
          >
            Beaconhouse School System
          </h5>
        )}
       
        {boardCheck === 1 ? (
          <>
            <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
              {data?.paper_details[0]?.type_name}{" "}
              {data?.paper_details[0]?.session}
            </h5>
            <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
              {/* {data?.paperMcqs[0]?.board_name} */}
              KPK
            </h5>
          </>
        ) : (
          <>
            {boardCheck === 2 || boardCheck === 3 && (subjectCheck === 16 || subjectCheck ===  18 || subjectCheck ===  6 || 
            subjectCheck === 7 || subjectCheck === 11 || subjectCheck === 12 || subjectCheck === 13) ? (
              <>
                {console.log(boardCheck, subjectCheck, "BOARD CHECK", "SUBJECTCHECK")}
                {console.log(data?.paper_details[0])}
                <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                  {data?.paper_details[0]?.type_name}{" "}
                  {data?.paper_details[0]?.session}
                </h5>
                <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                  {data?.paper_details[0]?.paper_board_type_id === 3 ? "Federal" : data?.paper_details[0]?.board_type}
                </h5>
              </>
            ) : (
              <>
                {boardCheck === 4 && (subjectCheck === 16 || 18 || 17) ? (
                  <>
                    <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                      {data?.paper_details[0]?.type_name}{" "}
                      {data?.paper_details[0]?.session}
                    </h5>
                    <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                      {/* {data?.paper_details[0]?.board_type} */}
                      SOUTH
                    </h5>
                  </>
                ) : (
                  <>
                    <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                      {data?.paper_details[0]?.type_name}
                    </h5>
                    <h5 style={{ fontWeight: "400", marginBottom: 0 }}>
                      {data?.paper_details[0]?.session}
                    </h5>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className="col-2 font-weight-bold text-dark">
        {/* KPK Board */}
        {boardCheck === 1 &&
          (data?.paper_details[0]?.class_id === 1 ? "Class: IX" : "Class: X")}
        
        {/* Punjab Board */}
        {boardCheck === 2 &&
          (subjectCheck === 16 || subjectCheck === 18) &&
          (data?.paper_details[0]?.class_id === 1 ? "Class: IX" : "Class: X")}
        
        {/* Federal Board */}
        {(boardCheck === 3 ) && (subjectCheck === 13) &&
          (data?.paper_details[0]?.class_id === 1 ? "Class: IX" : "Class: X")}
        
        {/* South Board */}
        {boardCheck === 4 &&
          (subjectCheck === 16 || subjectCheck === 18 || subjectCheck === 17) &&
          (data?.paper_details[0]?.class_id === 1 ? "Class: IX" : "Class: X")}

        {/* DATE SECTION */}
        {/* {boardCheck === 3 && subjectCheck === 6 && (
          <>
            <span style={{ fontSize: "20px", fontWeight: "500" }}>{formattedDate(todayDate)}</span>
          </>
        )}
        {boardCheck === 3 && subjectCheck === 7 && (
          <>
            <span style={{ fontSize: "20px", fontWeight: "500" }}>{formattedDate(todayDate)}</span>
          </>
        )}
        {boardCheck === 3 && subjectCheck === 11 && (
          <>
            <span style={{ fontSize: "20px", fontWeight: "500" }}>{formattedDate(todayDate)}</span>
          </>
        )}
        {boardCheck === 3 && subjectCheck === 12 && (
          <>
            <span style={{ fontSize: "20px", fontWeight: "500" }}>{formattedDate(todayDate)}</span>
          </>
        )} */}
      </div>
    </div>
  );
};

export default PaperHeader;
