import React from "react";

// packages
import parse from "html-react-parser";

// util
import { convertToRoman } from "utils/convertToRoman";
import { intToRomanLower, numberToAlifNumerals, numberToArabicNumerals, numberToRoman } from "utils/Helper";
import { IMAGE_URL } from "constant";
import { number } from "prop-types";

export const UrduLongQuestions = ({
  data,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
  is10
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  let firstWriter = "";
  let secondWriter = "";
  let i = 0;
  // let lastQCheck = 0;
  // function incrementCheck() {
  //   lastQCheck++;
  // }
  // function setFirstWriter(string) {
  //   firstWriter = string;
  // }
  // function setSecondWriter(string) {
  //   secondWriter = string;
  // }
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        const updatedIndex = ele?.ui_style == "یا" ? i : i++;
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                  { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 12 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )}
                        {((updatedIndex) + startIndex + startingQuestionNumber) === 11 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                          </span>
                          )}
                          {((updatedIndex) + startIndex + startingQuestionNumber) !== 10 &&
                          ((updatedIndex) + startIndex + startingQuestionNumber) !== 11 && 
                          ((updatedIndex) + startIndex + startingQuestionNumber) !== 12  && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                          </span>
                          )}
                        {/* { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) !== 12 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )} */}

                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_number" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                  { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 12 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )}
                        { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 13 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )}
                        {!is10 && ((updatedIndex) + startIndex + startingQuestionNumber) !== 12 
                        && !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) !== 13 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )}
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${numberToArabicNumerals(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}

            {(ele.ui_style == "" || ele.ui_style == "یا") && (
              <>
                {ele?.text != "" ? (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <>
                        <div className="col-12 text-center">
                          <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                        </div>
                        {/* {numberToArabicNumerals(
                            (updatedIndex) + startIndex + startingQuestionNumber
                          )} */}
                          {/* {ele?.question_ids?.map((ques, index) => {
                           return (
                             ques.chapter_name == "مختلف شخصیات کا تعارف" && (
                               <>
                               {lastQCheck > 0 && (
                                 setSecondWriter(ques.question)
                               )}
                                 {lastQCheck === 0 && (
                                   <>
                                       {incrementCheck()}
                                       {setFirstWriter(ques.question)}
                                   </>
                                 )}
                               </>
                             )
                           );
                          })} */}
                      {!is10 && (
                        <>
                        {/* {ele?.question_ids[0].chapter_name == "مختلف شخصیات کا تعارف" && (
                          <>
                          {ele?.text}
                          </>
                        )}
                        {ele?.question_ids[0].chapter_name != "مختلف شخصیات کا تعارف" && (
                          ele?.text
                        )} */}
                        {ele?.text}
                        </>
                      )}
                      </>
                    ) : (
                      <div className="col-12 text-right">
                        {console.log((updatedIndex) + startIndex + startingQuestionNumber)}
                        { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 10 // First Long Question's No.
                         && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber // Originally 10. Now 10 - 2 = 8. Q No. 8 it will be
                          )}
                          ۔{" "}
                        </span>
                        ) }
                        <span>{ele?.text}</span>
                      </div>
                    )}
                    <div className="col-10 d-flex" style={!is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 11
                      || !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 13 || !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 10 ? { flexDirection: "column" } : {}}>
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                          <div className={((updatedIndex) + startIndex + startingQuestionNumber) === 14 ? "d-flex col-3" : 'd-flex' }>
                            <span style={ !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 11 ? { marginLeft: "12px" } : {} }>
                              { !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) == 11 
                              || !is10 && ((updatedIndex) + startIndex + startingQuestionNumber) === 14 && (
                                `${intToRomanLower(index + 1)}.  `
                              )}
                              {ques.topic_name.includes("جملوں کی تشریح") && (
                                `${intToRomanLower(index + 1)}.  `
                              )}
                            </span>
                            {/* {console.log("ABC ABC ABC;")}
                            {console.log(ques)}
                            {ques.chapter_name == "مختلف شخصیات کا تعارف" && (
                              <>
                              {lastQCheck > 0 && (
                                setSecondWriter(ques.question)
                              )}
                                {lastQCheck === 0 && (
                                  <>
                                      {incrementCheck()}
                                      {setFirstWriter(ques.question)}
                                  </>
                                )}
                              </>
                            )} */}
                            {/* {ques.chapter_name != "مختلف شخصیات کا تعارف" && (
                            )} */}
                             <span>{parse(`${ques.question}`)}</span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-2 text-left">{ele.marks}</div>
                  </div>
                ) : (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <div className="col-12 text-center">
                        <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                      </div>
                    ) : (
                      <div className="col-1 text-right">
                        {((updatedIndex) + startIndex + startingQuestionNumber) === 11 && ( // Letter Long Question
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                        </span>
                        )}
                        {((updatedIndex) + startIndex + startingQuestionNumber) === 10 && (
                          <span>
                          سوال نمبر
                          {numberToArabicNumerals(
                            (updatedIndex - 2) + startIndex + startingQuestionNumber
                          )}
                          ۔{" "}
                          </span>
                          )}
                      </div>
                    )}

                    <div className="col-10 d-flex text-right">
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                          {console.log("QUESTIONNNN")}
                          {console.log(ques)}
                            <span>{parse(`${ques.question}`)}</span>
                          </>
                        );
                      })}
                    </div>
                    <div className="col-1 text-right">{ele.marks}</div>
                  </div>
                )}
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex">
                    <span className="font-weight-bold">
                      سوال نمبر
                      {numberToArabicNumerals(
                        updatedIndex + startIndex + startingQuestionNumber
                      )}
                      ۔{" "}
                    </span>
                    <span>
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className="col-12"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const PunjabUrduLongQuestions = ({
  data,
  is10,
  subject_id,
  startIndex,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = 1;
  let i = 0;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => {
        console.log("ELE:");
        console.log(ele);
        const updatedIndex = ele?.ui_style == "یا" ? i : i++;
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{`${parse(ques.question)}`}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}

            {ele.ui_style == "bullet_romen_line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                <div className="row">
                  {ele?.question_ids?.map((ques, index) => {
                    const isEvenIndex = index % 2 === 0; // Check if the index is even

                    return (
                      <div
                        key={index} // Always include a key when rendering a list of elements
                        className={`col-6`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: isEvenIndex ? "left" : " right",
                          margin: 0,
                          padding: "0 10px", // Add some padding for visual separation
                        }}
                      >
                        <span
                          style={{
                            marginRight: isEvenIndex ? "0" : "5px",
                            marginLeft: isEvenIndex ? "5px" : "0",
                            fontWeight: "bold",
                          }}
                        >
                          {is10
                            ? `(${numberToAlifNumerals(index + 1)})`
                            : `${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen_3line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                <div className="row">
                  {ele?.question_ids?.map((ques, index) => {
                    const isEvenIndex = index % 2 === 0; // Check if the index is even

                    return (
                      <div
                        key={index} // Always include a key when rendering a list of elements
                        className={`col-4`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          textAlign: isEvenIndex ? "left" : " right",
                          margin: 0,
                          padding: "0 10px", // Add some padding for visual separation
                        }}
                      >
                        <span
                          style={{
                            marginRight: isEvenIndex ? "0" : "5px",
                            marginLeft: isEvenIndex ? "5px" : "0",
                            fontWeight: "bold",
                          }}
                        >
                          {is10
                            ? `(${numberToArabicNumerals(index + 1)})`
                            : `${convertToRoman(index + 1)}.`}
                        </span>
                        <span>{parse(`${ques.question}`)}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {(ele.ui_style == "" || ele.ui_style == "یا") && (
              <>
                {ele?.text != "" ? (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <div className="col-12 text-center">
                        <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                      </div>
                    ) : (
                      <>
                      <div className="col-10 text-right">
                        <span>
                          {!is10 && "سوال نمبر"}
                          {updatedIndex + startIndex + startingQuestionNumber}۔
                        </span>
                        {ele.text.includes('000') && (
                      ele.text.replace('000', ele.question_ids[0].chapter_name)
                    )}
                    {!ele.text.includes('000') && (
                      ele.text
                    )} 
                        {/* {ele.text} */}
                      </div>
                    <div className="col-2 text-right">{ele.marks}</div>
                    </>
                    )}

                    <div className="col-10 d-flex">
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            {ques.main_question_text.includes('000') && (
                              <>
                              </>
                              // <span>{parse(`${ques.question}`)}</span>
                            )}
                            {!ques.main_question_text.includes('000') && (
                              <span>{parse(`${ques.question}`)}</span>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`row text-right clearfix mb-2 font-weight-bold`}
                  >
                    {ele?.ui_style == "یا" ? (
                      <>
                        <div className="col-12 text-center">
                          <span>۔۔۔۔۔۔یا۔۔۔۔۔</span>
                        </div>
                        <div className="col-10 d-flex text-right" style={{justifyContent: "space-evenly"}}>
                          {ele?.question_ids?.map((ques, index) => {
                            return (
                              <>
                              {`${convertToRoman(index + 1)}.`}
                                <span>{parse(`${ques.question}`)}</span>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-10 text-right d-flex">
                          سوال نمبر
                          {updatedIndex + startIndex + startingQuestionNumber}۔
                          {ele?.question_ids?.map((ques, index) => {
                            return (
                              <>
                                <span>{parse(`${ques.question}`)}</span>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                    <div className="col-1 text-right">{ele.marks}</div>
                  </div>
                )}
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex">
                    <span className="font-weight-bold">
                      {!is10 && "سوال نمبر"}
                      {updatedIndex + startIndex + startingQuestionNumber}۔
                      <span>{ele?.text}</span>۔{" "}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                  <div className="col-12">
                    <span>
                      {ele?.question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className="col-12"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const UrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
  is10
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-12 text-right">
          <span>ہدایات: {data?.text}</span>
        </div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div className="mb-3">
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele.text}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        
                        <span>{parse(`${ques.question}`)}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_romen_line" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10 d-flex">
                    <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  {!is10 && (
                    <span>
                    سوال نمبر
                    {/* { index + startingQuestionNumber === 9 ? '' : }
                    { index + startingQuestionNumber === 7 ? numberToArabicNumerals((index - 1) + startingQuestionNumber) : 
                      index + startingQuestionNumber === 9 ? '' : */}
                      {console.log(index + startingQuestionNumber)}
                      {console.log(ele?.sq_question_ids[0]?.main_question_text)}
                    { !is10 && index + startingQuestionNumber === 6 ? (numberToArabicNumerals((index - 2) + startingQuestionNumber) + `۔  `) : '' }
                    { !is10 && index + startingQuestionNumber === 7 ? (numberToArabicNumerals((index - 1) + startingQuestionNumber) + `۔  `) : '' }
                    { !is10 && index + startingQuestionNumber === 8 ? (numberToArabicNumerals((index - 2) + startingQuestionNumber) + `۔  `) : '' }
                    { !is10 && index + startingQuestionNumber === 9 ? (numberToArabicNumerals((index - 2) + startingQuestionNumber) + `۔  `) : ''}
                    { !is10 && index + startingQuestionNumber !== 6 && !is10 
                    && index + startingQuestionNumber !== 8 
                    && !is10 && index + startingQuestionNumber !== 9 
                    && !is10 && index + startingQuestionNumber !== 7 
                    ? numberToArabicNumerals(index + startingQuestionNumber) : '' }
                    
                      {!is10 && index + startingQuestionNumber === 7 ? ` الف)  ` : ''}

                    {/* { !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٢" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٣" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٤" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٥" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٦" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٧"
                      || !is10 && numberToArabicNumerals((index - 1) + startingQuestionNumber) === "٧"
                      || !is10 && numberToArabicNumerals((index) + startingQuestionNumber) === "٩" || !is10 && ((index) + startingQuestionNumber) === 9
                      ? ele?.sq_question_ids[1]?.main_question_text : <></>} */}
                      {!is10 && (index + startingQuestionNumber) === 9
                      || !is10 && (index + startingQuestionNumber) === 10
                      || !is10 && (index + startingQuestionNumber) === 8
                      || !is10 && (index + startingQuestionNumber) === 7
                      || !is10 && (index + startingQuestionNumber) === 6
                      || !is10 && (index + startingQuestionNumber) === 5
                      || !is10 && (index + startingQuestionNumber) === 4
                      || !is10 && (index + startingQuestionNumber) === 3
                      || !is10 && (index + startingQuestionNumber) === 2 ? 
                      <>
                        {ele?.sq_question_ids[0]?.main_question_text} 
                      </>
                        : <></>}
                  </span>
                  )}
                  <div className="col-10 d-flex" style={ !is10 && (index + startingQuestionNumber) === 6 || !is10 && !(numberToArabicNumerals(index + startingQuestionNumber) == "٦") ? { justifyContent: "flex-start", gap: "90px" } : {} }>
                    {is10 && (
                      <span>
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                    </span>
                    )}
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <span>{parse(`${ques.question}`)}</span>
                        </>
                      );
                    })}
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex" style={ !is10 ? {flexDirection: "column" } : {} }>
                    <span className="font-weight-bold">
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                      { !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٢" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٣" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٤" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٥" 
                      ? ele?.sq_question_ids[1]?.main_question_text : <></>}
                    </span>
                    <span style={ !is10 ? { display: "flex", justifyContent: "start" } : {} }>
                      {ele?.sq_question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className={!is10 ? "col-2" : "col-12" }
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${intToRomanLower(index + 1)})`}
                              </span>
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "noNumberBullet" && (
              <>
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex" style={ !is10 ? {flexDirection: "column" } : {} }>
                    <span className="font-weight-bold">
                      سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "}
                      { !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٢" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٣" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٤" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٥" 
                      ? ele?.sq_question_ids[1]?.main_question_text : <></>}
                    </span>
                    <span style={ !is10 ? { display: "flex", justifyContent: "start" } : {} }>
                      {ele?.sq_question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              className={!is10 ? "col-2" : "col-12" }
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                              }}
                            >
                              {/* <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span> */}
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
            {ele.ui_style == "یا" && (
              <>
              <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              ۔۔۔۔۔۔یا۔۔۔۔۔
                    </div> 
                <div className={`row text-right clearfix mb-2 `}>
                  <div className="col-10 d-flex" style={ !is10 ? {flexDirection: "column" } : {} }>
                    <span className="font-weight-bold">
                      {/* سوال نمبر
                      {numberToArabicNumerals(
                        index + startingQuestionNumber
                      )}۔{" "} */}
                      {/* {(index + startingQuestionNumber) === 8 && (
                        <>
                          سوال نمبر
                        {numberToArabicNumerals(
                          (index - 1) + startingQuestionNumber
                        )}۔{" "}
                        </>
                      )} */}
                      { !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٢" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٣" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٤" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٥" 
                      || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٦" || !is10 && numberToArabicNumerals(index + startingQuestionNumber) === "٧"
                      ? ele?.sq_question_ids[0]?.main_question_text : <></>}
                      {!is10 && numberToArabicNumerals((index - 1) + startingQuestionNumber) === "٧" ? ele?.sq_question_ids[0].chapter_id === 841 ? ` ب)` + `مندرجہ ذيل الفاظ کے سابقے لاحقے لکھيے` : '' : ''}
                      {!is10 && numberToArabicNumerals((index - 1) + startingQuestionNumber) === "٧" ? ele?.sq_question_ids[0].chapter_id === 842 ? ` ب)` + `مندرجہ ذيل الفاظ کے متضاد لکھيے` : '' : ''}
                      {!is10 && numberToArabicNumerals((index - 1) + startingQuestionNumber) === "٧" ? ele?.sq_question_ids[0].chapter_id === 843 ? ` ب)` + `مندرجہ ذيل الفاظ کے مترادف لکھيے` : '' : ''}
                    </span>
                    <span style={ !is10 ? { display: "flex", justifyContent: "start" } : {} }>
                      {ele?.sq_question_ids?.map((ques, index) => {
                        return (
                          <>
                            <p
                              // className={!is10 ? "col-2" : "col-12" }
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                margin: 0,
                                width: "100px"
                              }}
                            >
                              {/* <span
                                style={{
                                  marginRight: "5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${numberToAlifNumerals(index + 1)})`}
                              </span> */}
                              {/* {console.log("QUESTION FOR M:")}
                              {console.log(ques)} */}
                              <span>{parse(`${ques.question}`)}</span>
                            </p>
                          </>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export const PunjabUrduShortQuestions = ({
  data,
  subject_id,
  class_id,
  isMcqExist,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <div className={`row clearfix mb-2 font-weight-bold`}>
        <div className="col-10 text-right">
          <span>
            {" "}
            {class_id != 2 && "سوال نمبر"}2۔ {data?.text}
          </span>
        </div>
        <div className="col-2">{data?.marks}</div>
      </div>
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div key={index} className="mb-3">
            {ele.ui_style == "bullet" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-1">{ele.text}:</div>
                  <div className="col-11 text-right">
                    {ele?.sq_question_ids?.map((ques, index) => {
                      return (
                        <>
                          <p
                            className="col-12"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              margin: 0,
                            }}
                          >
                            <span
                              style={{
                                margin: "0 0 0 10px",
                                fontWeight: "bold",
                              }}
                            >
                              {`${index + 1}. `}
                            </span>
                            <span>{parse(`${ques.question}`)}</span>
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {ele.ui_style == "bullet_romen" && (
              <>
                <div
                  className={`row text-right clearfix mb-2 font-weight-bold`}
                >
                  <div className="col-10">{ele.text}</div>
                  <div className="col-2 text-right">{ele.marks}</div>
                </div>

                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <p
                        className="col-12"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          margin: 0,
                        }}
                      >
                        <span
                          style={{ marginRight: "5px", fontWeight: "bold" }}
                        >
                          {`${convertToRoman(index + 1)}.`}
                        </span>
                        <span className={ques.main_question_text.includes("حصہ نظم") || ques.main_question_text.includes("حصہ غزل") ? "col-7" : ''} 
                        style={ ques.main_question_text.includes("حصہ نظم") || ques.main_question_text.includes("حصہ غزل") ? 
                        {display: "flex", justifyContent: "space-between", alignItems: "flex-start"} : {} }>
                          {parse(`${ques.question}`.replace('<p class="ql-align-right ql-direction-rtl"><br></p>', ''))}</span>
                      </p>
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
