import React, { useState, useEffect } from "react";

// api's hook
import {
  useGetBoards,
  useGetClasses,
  useGetSubjects,
  useGetChapters,
  useGetQuestions,
  useGetTopics,
  useGetQuestionList,
  useGetFilterQuestions,
  useGetFilterMCQS
} from "hooks/api/subject";

import { useAppContext } from "context/app.context";

const MCQsFilter = ({
  data,
  setRows,
  setLoading,
  searchKey,
  contextKey,
  isLoading,
  showChapters,
  showTopics,
  // showTypes,
  defaultPage,
  setDefaultPage,
  handleChangePage
}) => {
  const { state: appState, dispatch } = useAppContext();

  const [state, setState] = useState(null);
  const [search, setSearch] = useState("");

  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: chapters } = useGetChapters();
  const { data: topics } = useGetTopics();
  // const { data: filterRecord } = useGetFilterQuestions();

  const [fetchRecords, setFetchRecords] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Stores immediate input
  const [debouncedSearch, setDebouncedSearch] = useState("");

const [filters, setFilters] = useState({
  board: "",
  classID: "",
  subject: "",
  chapter: "",
  topic: "",
  type: "",
  search: "",
});

  const { data: filterMCQS, refetch: Another } = useGetFilterMCQS( defaultPage, filters, (data) => {
    setLoading(false);
    console.log("filterRecord has data:", data);
    if (data?.data) {
      console.log("filterRecord has data:", data.data);
      setRows(data.data);
    }
  });
  console.log("MCQS:", filterMCQS);

     useEffect(() => {
          setLoading(true);
          Another();
      }, [defaultPage]);

    useEffect(() => {
      // let board = localStorage.getItem("board");
      // let classID = localStorage.getItem("classID");
      // let subject = localStorage.getItem("subject");
      // let chapter = localStorage.getItem("chapter");
      // let topic = localStorage.getItem("topic");
      // let type = localStorage.getItem("type");
      // let search = localStorage.getItem("search");
      //  if(board) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["board"]: board      
      //   }))
      //  }
      //  if(classID) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["classID"]: classID      
      //   }))
      //  }
      //  if(subject) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["subject"]: subject      
      //   }))
      //  }
      //  if(chapter) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["chapter"]: chapter      
      //   }))
      //  }
      //  if(topic) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["topic"]: topic      
      //   }))
      //  }
      //  if(type) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["type"]: type      
      //   }))
      //  }
      //  if(search) {
      //   setFilters((prev) => ({
      //     ...prev,
      //     ["search"]: search      
      //   }))
      //  }
      setFilters((prev) => ({
        ...prev,
        board: localStorage.getItem("board") || "",
        classID: localStorage.getItem("classID") || "",
        subject: localStorage.getItem("subject") || "",
        chapter: localStorage.getItem("chapter") || "",
        topic: localStorage.getItem("topic") || "",
        type: localStorage.getItem("type") || "",
        search: localStorage.getItem("search") || "",
      }));
        // localStorage.removeItem("board");
        // localStorage.removeItem("classID");
        // localStorage.removeItem("subject");
        // localStorage.removeItem("chapter");
        // localStorage.removeItem("topic");
        // localStorage.removeItem("type");
        // localStorage.removeItem("search");
    }, []);
    
  useEffect(() => {
    if (filters) {
      setLoading(true);
      Another();
    }
  }, [filters, Another]);

// useEffect(() => {
//   if (filters) {
//     setLoading(true);
//     refetch();
//     if (filterRecord && filterRecord.data) {
      
//       // Do something with filterRecord.data
//     }
    
//   }
// }, [filters, refetch]);

// Handle dropdown changes
const handleSelectChange = (key, value) => {
  setFilters((prev) => ({
    ...prev,
    [key]: value,
  }));
  localStorage.setItem(key, value);
  setDefaultPage(0);
  handleChangePage(0);
  console.log("KEY VALUE", key, value);
};


  // const { data: questions } = useGetQuestionList(defaultPage);

  useEffect(() => {
    if (isLoading) return;

    if (!!appState[contextKey]) {
      console.log("CONTEXT KEY:");
      const {
        board_id = null,
        class_id = null,
        subject_id = null,
        chapter_id = null,
        topic_id = null,
        type = null,
        search = "",
      } = appState[contextKey];

      setState({ board_id, class_id, subject_id, chapter_id, topic_id, type });
      setSearch(search);

      const filterArrayData = filterArray({
        board_id,
        class_id,
        subject_id,
        chapter_id,
        topic_id,
        type,
      });
      const searchData = filterArrayData.filter((ele) =>
        ele[searchKey].toLowerCase().includes(search.toLowerCase())
      );

      setRows(searchData);
      setLoading(false);
    } else {
      console.log("ROWS:");
      console.log("ROW DATA", data);
      setRows(data);
      setLoading(false);
    }
  }, [appState[contextKey], data, isLoading, defaultPage]);

  // const handleSelectChange = (key, value) => {
  //   setLoading(true);
  //   dispatch(contextKey, { ...appState[contextKey], [key]: value });
  // };

  

  // const handleSearch = (value) => {
  //   dispatch(contextKey, { ...appState[contextKey], search: value });
  // };

  // const handleSearch = (value) => {
  //   setTimeout(() => {
  //     setFilters((a) => ({
  //       ["search"]: value
  //     }))
  //   }, 3000)
  // }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchTerm); // Updates state after delay
    }, 1000);

    return () => clearTimeout(timeoutId); // Clears timeout if user types again
  }, [searchTerm]); // Runs whenever searchTerm changes

  useEffect(() => {
    if (debouncedSearch) {
      localStorage.setItem("search", debouncedSearch);
      setFilters((prev) => ({
        ...prev,
        search: debouncedSearch,
      }));
    }
  }, [debouncedSearch]); // Runs when debouncedSearch updates

  const handleClear = () => {
    setLoading(false);
    dispatch(contextKey, {});
    localStorage.removeItem("board");
    localStorage.removeItem("classID");
    localStorage.removeItem("subject");
    localStorage.removeItem("chapter");
    localStorage.removeItem("topic");
    localStorage.removeItem("type");
    localStorage.removeItem("search");
    setSearch("");
    setSearchTerm("");
    setDefaultPage(0);
    handleChangePage(0);
    setFilters({
      board: "",
      classID: "",
      subject: "",
      chapter: "",
      topic: "",
      type: "",
      search: "",
    });
  };

  const removeNullValues = (obj) => {
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  };

  const filterArray = (filterObj) => {
    const obj = removeNullValues({ ...filterObj });

    const filteredArray = data !== undefined ? data.filter((item) => {
      let match = true;
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (item[key] != obj[key]) {
            match = false;
            break;
          }
        }
      }
      return match;
    }) : [];

    return filteredArray;
  };

  return (
    <div className="body">
      <div className="row clearfix">
        <div className="col-4 mb-4 mb-4">
          <CustomSelect
            label="Boards"
            value={filters.board !== "" ? filters.board : localStorage.getItem("board") || ""}
            onChange={(e) => handleSelectChange("board", e.target.value)
            }  
            options={boards?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        <div className="col-4 mb-4">
          <CustomSelect
            label="Classes"
            value={filters.classID !== "" ? filters.classID : localStorage.getItem("classID") || ""}
            onChange={(e) => handleSelectChange("classID", e.target.value)}
            options={classes?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        <div className="col-4 mb-4">
          <CustomSelect
            label="Subjects"
            value={filters.subject !== "" ? filters.subject : localStorage.getItem("subject") || ""}
            onChange={(e) => handleSelectChange("subject", e.target.value)}
            options={subjects?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        {showChapters && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Chapters"
              value={filters.chapter !== "" ? filters.chapter : localStorage.getItem("chapter") || ""}
              onChange={(e) => handleSelectChange("chapter", e.target.value)}
              options={chapters?.data
                ?.filter(
                  (chp) =>
                    chp?.board_id == filters?.board &&
                    chp?.subject_id == filters?.subject &&
                    chp?.class_id == filters?.classID
                )
                ?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
            />
          </div>
        )}

        {showTopics && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Topics"
              value={filters.topic !== "" ? filters.topic : localStorage.getItem("topic") || ""}
              onChange={(e) => handleSelectChange("topic", e.target.value)}
              options={topics?.data
                ?.filter((top) => top?.chapter_id == filters?.chapter)
                ?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
            />
          </div>
        )}

        {/* {showTypes && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Type"
              value={filters.type !== "" ? filters.type : localStorage.getItem("type") || ""}
              onChange={(e) => handleSelectChange("type", e.target.value)}
              options={[
                { value: "short", label: "Short Questions" },
                { value: "long", label: "Long Questions" },
              ]}
            />
          </div>
        )} */}

        <div className="col-4 mb-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Search</span>
            </div>
            <input
              type={"text"}
              placeholder={`Search...`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="col-4 mb-4">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="button"
              className="btn btn-warning"
              title="Clear All"
              // disabled={
              //   Object.keys(
              //     removeNullValues({
              //       ...state,
              //       search: search == "" ? null : search,
              //     }) || {}
              //   ).length === 0
              // }
              onClick={handleClear}
            >
              <span className="sr-only">Clear All</span>
              <i className="fa fa-remove"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCQsFilter;

const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <label className="input-group-text">{label}</label>
      </div>
      <select className="custom-select" value={value} onChange={onChange}>
        <option value="">-- select an option --</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
