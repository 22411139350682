import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// util
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";
import { intToRoman, numberToAlphabet } from "utils/Helper";

const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const ShortQuestions = ({
  is10,
  data,
  subject_id,
  isMcqExist,
  subjectCheck,
}) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isEnglish = ["11"].includes(`${subject_id}`);
  const startingQuestionNumber = !isMath && isMcqExist ? 2 : 1;
  return (
    <div>
      {isMath && (
        <style>
          {
            `
              p {
                font-size: 20px;
              }
            `
          }
        </style>
      )}
      {data?.paperShortQuestions?.map((ele, index) => {
        return (
          <div className="mb-3">
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  {is10 && subjectCheck === 12 ? (
                    <>{index + startingQuestionNumber}.</>
                  ) : subjectCheck === 13 ? (
                    <>{index + 1}.</>
                  ) : (
                    <>
                      {subjectCheck === 12 ? (
                        <>Q{index + startingQuestionNumber}.</>
                      ) : !is10 && subjectCheck === 6 ? (
                        <></>
                      ) : (
                        <>Q-{intToRoman(index + startingQuestionNumber)}.</>
                      )}
                    </>
                  )}{" "}
                </span>
                {intToRoman(index + startingQuestionNumber) === "IV" && (
                  <span>{ele.text}</span>
                )}
                {intToRoman(index + startingQuestionNumber) !== "IV" && (
                  <span style={subjectCheck === 13 ? { fontSize: "21px" } : {}}>
                    {ele.text}
                  </span>
                )}
              </div>
              <div className="col-2 text-right">
                {subjectCheck === 13 ||
                  subjectCheck === 12 ||
                  subjectCheck === 6 ||
                  subjectCheck === 7
                  ? ""
                  : ele.marks}
              </div>
            </div>
            {ele.ui_style == "bullet" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={
                          subjectCheck === 6
                            ? `Q.${index + 1}`
                            : `${index + 1}.`
                        }
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "rtl_row_2_bullet" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={`(${numberToAlphabet(index + 1)})`}
                        isMath={isMath}
                        index={index}
                        quesNum={`${index + 1}`}
                        isEnglish={isEnglish}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "bullet_roman" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={`${convertToRoman(index + 1)}.`}
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
            {ele.ui_style == "" && (
              <>
                {ele?.sq_question_ids?.map((ques, index) => {
                  return (
                    <>
                      <BulletShortSubQuestions
                        ques={ques}
                        text={parse(`${ques.question}`)}
                        number={``}
                        isMath={isMath}
                        index={index}
                      />
                    </>
                  );
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const LongQuestions = ({ data, startIndex, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
              {ele.text}
            </div>
            {/* <div className="col-2 text-right">{ele.marks}</div> */}
          </div>

          {ele.ui_style == "math_long_q9_include_or_and_single_marks" &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${alphabets[QueIndex + 0]
                    })`}</span>
                  <span style={{ width: "100%" }}>
                    <MathJax math={`${ques.question}`} />
                  </span>

                  {QueIndex == 0 && (
                    <div
                      style={{ maxWidth: "100%" }}
                      className="text-right font-weight-bold"
                    >
                      (0{ques.marks})
                    </div>
                  )}
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {ele?.question_ids.length > 1 && QueIndex == 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </>
            ))}

          {!ele.ui_style &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${alphabets[QueIndex + 0]
                    })`}</span>
                  <span style={{ width: "100%" }}>
                    {isMath ? (
                      <MathJax math={`${ques.question}`} />
                    ) : (
                      parse(`${ques.question}`)
                    )}
                  </span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}
              </>
            ))}
        </div>
      ))}
    </div>
  );
};

export const LongEnglishQuestions = ({
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  let letterORApplicationCheck = 0;
  function incrementCheckLetter() {
    letterORApplicationCheck++;
  }
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>
                  {intToRoman(index + startIndex + startingQuestionNumber) != 'XI' && (
                    <>
                    Q-{intToRoman(index + startIndex + startingQuestionNumber)}.{" "}
                    </>
                  )}
                </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          }

          {ele.ui_style == "rtl_row_2_bullet" && (
            <>
              <div className="row rtl-list text-right">
                {ele?.question_ids?.map((ques, queIndex) => (
                  <BulletSubQuestions
                    col="col-6"
                    number={`${queIndex + 1}.`}
                    text={parse(`${ques.question}`)}
                  />
                ))}
              </div>
            </>
          )}

          {ele.ui_style == "single_line_bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <>
                {letterORApplicationCheck === 2 && (
                  <div className="col-12 text-start  mt-1 mb-1">
                    {`${numberToAlphabet(queIndex + 0)}. `}
                      <span className={"col-12 text-start font-weight-bold mt-1 mb-1"}>Write a story with the help of the given outlines and suitable words</span>
                  </div>
                )}
                  <BulletSubQuestions
                    col="col-12"
                    number={ letterORApplicationCheck === 1 || letterORApplicationCheck === 2 ? `` : `${numberToAlphabet(queIndex + 1)}.`}
                    text={parse(`${ques.question}`)}
                    letterORApplicationCheck={letterORApplicationCheck}
                    />
                  {letterORApplicationCheck === 0 && (
                    <div className="col-12 text-center font-weight-bold mt-1 mb-1">
                      OR
                    </div> 
                  )}
                  {incrementCheckLetter()}
                </>
              ))}
            </div>
          )}

          {ele.ui_style == "bullet" && (
            <div className="row mx-5">
              {ele?.question_ids?.map((ques, queIndex) => (
                <>
                  <BulletSubQuestions
                    col={is10 ? ques.main_question_text.includes('Translate the following paragraph in Urdu.') ? 'col-12' : `col-4` : `col-12`}
                    number={
                      is10
                        ? `(${convertToRoman(queIndex + 1)})`
                        : `${numberToAlphabet(queIndex + 1)}.`
                    }
                    text={parse(`${ques.question}`)}
                    style={ques.main_question_text.includes('Translate the following sentences into English') ? { direction: "rtl" } : {}}
                  />
                </>
              ))}
            </div>
          )}

          {ele.ui_style == "punjab_10_english_essay_writing_and_para" && (
            <Punjab10EssayWriting data={ele} />
          )}

          {ele.ui_style == "punjab_10_translation_with_ten_sentences" && (
            <Punjab10ParaTranslation data={ele} />
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `${(index + startIndex + startingQuestionNumber) === 9 ? '' : ``}`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="text-right font-weight-bold"
                      >
                        {ele.marks}
                      </div>
                    )}
                  </div>
                  {queIndex + 1 < ele?.question_ids?.length || (index + startIndex + startingQuestionNumber) === 9 && is10 ||
                  (index + startIndex + startingQuestionNumber) === 10 && is10 && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const LongPhysicsQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <style>
      {`
        p {
          font-size: 18px;
        }
      `}
    </style>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-1">
          {
            <div className="row clearfix">
              <div style={{ marginLeft: "20px", marginRight: "5px" }} className="font-weight-bold">
                {subjectCheck === 7 ? (
                  <span>
                    Q.{intToRoman(index + startIndex + startingQuestionNumber)}{" "}
                  </span>
                ) : subjectCheck === 6 ? (
                  <span>
                    Q-{index + startIndex + startingQuestionNumber}{" "}
                  </span>
                ) : subjectCheck === 13 ? (
                  <span>{index + startIndex + 1}. </span>
                ) : (
                  <span>{index + startIndex + startingQuestionNumber}. </span>
                )}{" "}
                {ele.text}
              </div>
              <div className="col-9">
                {ele.ui_style == "single_line_bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={
                          subjectCheck === 6 || subjectCheck === 7
                            ? `(${numberToAlphabet(queIndex + 1)})`
                            : `${convertToRoman(queIndex + 1)}.`
                        }
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
                {ele.ui_style == "bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
              {subjectCheck === 13 && (
                <div className="col-2 text-right">{`(${ele.marks})`}</div>
              )}
              {subjectCheck !== 13 && (
                <div className="col-2 text-right">{`${ele.marks}`}</div>
              )}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export const LongBioQuestions = ({
  subjectCheck,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      <style>
      {`
        p {
          font-size: 18px;
        }
      `}
    </style>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-1">
          {
            <div className="row clearfix d-flex justify-content-between">
              <div className="font-weight-bold" style={{ width: "60px", display: "flex", justifyContent: "center" }}>
                {subjectCheck === 7 ? (
                  <span className="m-1">
                    Q.{intToRoman(index + startIndex + startingQuestionNumber)}{" "}
                  </span>
                ) : ''}
                {ele.text}
              </div>
              <div className="col-9">
                {ele.ui_style == "single_line_bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={
                          subjectCheck === 6 || subjectCheck === 7
                            ? `(${numberToAlphabet(queIndex + 1)})`
                            : `${convertToRoman(queIndex + 1)}.`
                        }
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
                {ele.ui_style == "bullet" && (
                  <div className="row" style={{ marginBottom: "0.3rem" }}>
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
              {subjectCheck === 13 && (
                <div className="col-2 text-right">{`(${ele.marks})`}</div>
              )}
              {subjectCheck !== 13 && (
                <div className="col-2 text-right">{`${ele.marks}`}</div>
              )}
            </div>
          }
        </div>
      ))}
    </div>
  );
};


export const LongMathQuestions = ({
  isMath,
  data,
  startIndex,
  isMcqExist,
  is10,
}) => {
  const startingQuestionNumber = !isMath && isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-0" >
          {
            <div className="row clearfix  mb-2">
              <div className="font-weight-bold">
                <span>
                  {!is10 && "Q"}
                  {index + startIndex + startingQuestionNumber}.{" "}
                </span>{" "}
                {ele.text}
              </div>
              <div className="col-11">
                {ele.ui_style == "" && (
                  <div className="row">
                    {ele?.question_ids?.map((ques, queIndex) => (
                      <BulletSubQuestions
                        col="col-12"
                        // number={`${numberToAlphabet(queIndex + 1)}.`}
                        text={parse(`${ques.question}`)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      ))}
    </div>
  );
};

const Punjab10EssayWriting = ({ data }) => {
  const essayWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 446
  );
  const paragraphWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 446
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "35px" }} />
      <div className="row">
        {essayWriting.map((ques, queIndex) => (
          <BulletSubQuestions
            col="col-4"
            number={`(${convertToRoman(queIndex + 1)})`}
            text={parse(`${ques.question}`)}
          />
        ))}

        {paragraphWriting.length > 0 && (
          <>
            <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              OR
            </div>

            <div
              className="col-12 font-weight-bold mb-1"
              style={{ marginLeft: "-10px" }}
            >
              Write a paragraph of 100-150 words on any ONE of the following
              topics:
            </div>

            {paragraphWriting.map((ques, index) => (
              <BulletSubQuestions
                col="col-4"
                number={`(${convertToRoman(index + 1)})`}
                text={parse(`${ques.question}`)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const Punjab10ParaTranslation = ({ data }) => {
  const paraTranslation = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 448
  );
  const tenSentences = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 448
  );
  return (
    <>
      <div className="row rtl-list text-right">
        {paraTranslation?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>

      {tenSentences.length > 0 && (
        <div
          className="text-center font-weight-bold mt-1 mb-1"
          style={{ width: "100%" }}
        >
          OR
        </div>
      )}

      <div className="row">
        {tenSentences?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>
    </>
  );
};

const BulletSubQuestions = ({ col, number, text, letterORApplicationCheck, style }) => {
  const boldFont = { fontWeight: "500", minWidth: "35px" };
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0, ...style }}
    >
      <span style={ letterORApplicationCheck === 0 || letterORApplicationCheck === 1 ? boldFont : {minWidth: "35px", fontWeight: "500"} }>{number}</span>
      <span style={ letterORApplicationCheck === 0 || letterORApplicationCheck === 1 ? boldFont : {}}>{text}</span>
    </p>
  );
};

const BulletShortSubQuestions = ({ ques, isMath, index, number, quesNum, isEnglish }) => {
//   let questionHTML = `${ques.question}`.replace(/<p>/g, '<p style="margin-bottom: 0;">');
//   let questionStatement = parse(questionHTML);
//   // console.log(quesNum);
//   // console.log(number);
//   // This block removes the questions part from Question number 3 as per requirements from Educators
// if(quesNum === "1" && number == "(a)" && isEnglish || quesNum === "2" && number == "(b)" && isEnglish) {
//     // alert("checked!");
//     let parser = new DOMParser();
//     let doc = parser.parseFromString(questionHTML, "text/html");

//     let pElem = doc.querySelectorAll("p");
//     if (pElem.length > 0) {
//         pElem[pElem.length - 1].remove();
//     }

//     let olElements = doc.querySelectorAll("ol");
//     if (olElements.length > 0) {
//         olElements[olElements.length - 1].remove();
//     }

//     let updatedHTML = doc.body.innerHTML;
//     questionStatement = parse(updatedHTML); 
//   console.log(questionStatement);
// }
// -----------
return (
    <>
      <p
        className="col-12"
        style={{
          display: "flex",
          alignItems: isMath ? "flex-start" : "flex-start",
          margin: 0,
        }}
      >
        <span style={{ minWidth: "35px", marginRight: "3px" }}>{`${number}`}</span>
        <span>
          {isMath ? (
            <MathJax math={`${ques.question}`} />
          ) : (
            // parse(`${ques.question}`.replace(/<p>/g, '<p style="margin-bottom: 0;">'))
            <>
              <span style={{ fontSize: "20px" }}>
                  {parse(`${ques.question}`.replace(/<p>/g, '<p style="margin-bottom: 0;">'))}
                </span>
            </>
          )}
        </span>
      </p>
      {ques.file && (
        <p
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-0.5rem",
            marginBottom: "1rem",
          }}
        >
          <img
            src={`${IMAGE_URL}/${ques.file}`}
            alt="image"
            style={{ maxWidth: "200px" }}
          />
        </p>
      )}
    </>
  );
};
