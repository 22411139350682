import React from "react";

// packages
import { Controller } from "react-hook-form";
import parse from "html-react-parser";

// components
import QuilTextEditor from "./QuilTextEditor";
import CKTextEditor from "./CKTextEditor";

export default function FormTextEditor({ isCKEditor, item, control, error, editorRef }) {
  return (
    <>
      <Controller
        control={control}
        name={item.name}
        defaultValue={""}
        // rules={{
        //   validate: {
        //     required: (v) => {
        //       if (item.required) {
        //         if (
        //           Object.prototype.toString.call(parse(`${v}`)) ==
        //             "[object Array]" &&
        //           parse(`${v}`)?.length > 0
        //         ) {
        //           return true;
        //         }

        //         if (
        //           Object.prototype.toString.call(parse(`${v}`)) ==
        //             "[object Object]" &&
        //           parse(`${v}`)?.props?.children?.length > 0
        //         ) {
        //           return true;
        //         }

        //         return `This field is required`;
        //       }
        //     },
        //   },
        // }}
        render={({ field: { value, onChange } }) => (
          <>
            <div className="input-group-prepend">
              <span className="input-group-text">{item.label}</span>
            </div>

            {isCKEditor ? (
              <CKTextEditor value={value} onChange={onChange} />
            ) : (
              <QuilTextEditor
                value={value}
                onChange={onChange}
                placeholder={item.placeholder}
                editorRef={editorRef}
              />
            )}

            {error?.message && (
              <ul className="parsley-errors-list filled">
                <li className="parsley-required">{error?.message}</li>
              </ul>
            )}
          </>
        )}
      />
    </>
  );
}
