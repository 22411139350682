import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";
import TBFilter from "components/TBFilter";

// hooks
import { useDeleteChapter, useGetChapters } from "hooks/api/subject";
import { useTable } from "hooks/custom/useTable";

// context
import { useAuthContext } from "context/auth.context";

//
import {
  dataReviewUser,
  defaultAdminPermissions,
  defaultEntryPermissions,
} from "constant";
import { CSVLink } from "react-csv";

const Chapter = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: chapters, refetch, isLoading } = useGetChapters();
  const deleteChapter = useDeleteChapter(refetch);

  return (
    <div className="container-fluid">
      <PageHeader HeaderText="Chapters" Breadcrumb={[{ name: "Chapters" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Chapters</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink filename={"all-chapter.csv"} data={rows}>
              {" "}
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export Chapters</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>

            {defaultEntryPermissions.includes(user.rolename.toLowerCase()) && (
              <Link to="/chapters/create">
                <button
                  type="button"
                  className="btn btn-success"
                  title="Create Chapter"
                >
                  <span className="sr-only">Create Chapter</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Create Chapter</span>
                </button>
              </Link>
            )}
          </div>
        </div>

        <TBFilter
          data={chapters?.data || []}
          setRows={setRows}
          setLoading={setLoading}
          searchKey="name"
          contextKey="CHAPTER_FILTERS"
          isLoading={isLoading}
        />

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>CHAPTER #</th>
                  <th>NAME</th>
                  {/* <th>TOPICS</th>
                <th>BOARD</th> */}
                  <th>CLASS</th>
                  <th>SUBJECT</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {" "}
                    {rows?.length > 0 ? rows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => (
                        <tr key={row.id}>
                          <td scope="row">{row.lesson_no}</td>
                          <th>{row.name}</th>

                          <td>{row.classname}</td>
                          <td>{row.subjectname}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-primary mr-1"
                                title="View"
                                onClick={() =>
                                  history.push(`/chapters/${row.id}`)
                                }
                              >
                                <span className="sr-only">View</span>
                                <i className="fa fa-eye"></i>
                              </button>

                              {defaultEntryPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  title="Edit"
                                  onClick={() =>
                                    history.push(`/chapters/edit/${row.id}`)
                                  }
                                >
                                  <span className="sr-only">Edit</span>
                                  <i className="fa fa-edit"></i>
                                </button>
                              )}

                              {defaultAdminPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  title="Delete"
                                  onClick={() => deleteChapter.mutate(row.id)}
                                >
                                  <span className="sr-only">Delete</span>
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      )): <tr>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "center",
                          height: "30vh",
                          fontSize: "1.5rem",
                          width: "100%",
                        }}
                      >
                        Oops! No data found
                      </td>
                      <td></td>
                      <td></td>
                    </tr>}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Chapter;
