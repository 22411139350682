import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// components
import PageHeader from "components/BreadCrumb";
import EntryFeedBackField from "components/EntryFeedBackField";

// hooks
import {
  useGetQuestion,
  useGetQuestions,
  useReviewQuestion,
} from "hooks/api/subject";

// context
import { useAuthContext } from "context/auth.context";

//
import { dataReviewUser, IMAGE_URL } from "constant";

const QuestionDetail = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const history = useHistory();
  const [state, setState] = useState({});

  const { data: question, refetch } = useGetQuestion(id);
  const [rows, setRows] = useState([]);
  const { data: questions } = useGetQuestions();
  const reviewQuestion = useReviewQuestion();
  useEffect(() => {
    console.log(questions);
    if (questions?.data && questions?.data.length > 0) {
      setRows(questions?.data);
    }
  }, [questions]);
  const getCurrentQuestionsIndex = () => {
    if (!rows || rows.length === 0) return -1; // Handle empty state
    return rows.findIndex((question) => question.id == id);
  };
  const navigateToQuestion = (newIndex) => {
    if (newIndex >= 0 && newIndex < rows.length) {
      const newQuestionId = rows[newIndex].id;
      history.push(`/questions/${newQuestionId}`);
    }
  };
  const onNext = () => {
    if (!rows || rows.length === 0) return; // Prevent errors if data isn't loaded

    const currentIndex = getCurrentQuestionsIndex();
    console.log(currentIndex)
    if (currentIndex !== -1 && currentIndex < rows.length - 1) {
      navigateToQuestion(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    if (!rows || rows.length === 0) return;

    const currentIndex = getCurrentQuestionsIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      navigateToQuestion(currentIndex - 1);
    }
  };

  const onSave = async () => {
    const body = { ...state };
    await reviewQuestion.mutateAsync({ id, body });
    refetch();
  };

  useEffect(() => {
    if (question?.data) {
      setState({
        status: question?.data[0]?.status,
        feedback: question?.data[0]?.feeback || "",
      });
    }
  }, [question]);

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Questions", navigate: "/questions" },
          { name: "Detail" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>Question Detail</h2>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Board:</th>
                <td>{question?.data[0].board_name}</td>
              </tr>
              <tr>
                <th scope="row">Subject:</th>
                <td>{question?.data[0].subjectname}</td>
              </tr>
              <tr>
                <th scope="row">Class:</th>
                <td>{question?.data[0].classname}</td>
              </tr>
              <tr>
                <th scope="row">Chapter Name:</th>
                <td>{question?.data[0].chapter_name}</td>
              </tr>
              <tr>
                <th scope="row">Topic Name:</th>
                <td>{question?.data[0].topic_name}</td>
              </tr>
              <tr>
                <th scope="row">Question:</th>
                <td
                  style={{
                    direction:
                      question?.data[0].subjectname == "Urdu" ? "rtl" : "",
                  }}
                >
                  {["5", "12"].includes(`${question?.data[0]?.subject_id}`) ? (
                    <MathJax math={question?.data[0].question} />
                  ) : (
                    parse(`${question?.data[0].question}`)
                  )}
                </td>
              </tr>
              {question?.data[0].file && (
                <tr>
                  <th scope="row">Image:</th>
                  <td>
                    <img
                      src={`${IMAGE_URL}/${question?.data[0].file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">Type:</th>
                <td>{question?.data[0].type}</td>
              </tr>
              <tr>
                <th scope="row">Marks:</th>
                <td>{question?.data[0].marks}</td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{question?.data[0].review_status}</td>
              </tr>
              <tr>
                <th scope="row">Feedback:</th>
                <td>{question?.data[0].feeback}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="body">
          <button
            type="submit"
            className="btn btn-success ml-auto"
            onClick={onPrevious}
            disabled={getCurrentQuestionsIndex() <= 0}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-success mx-3"
            onClick={onNext}
            disabled={getCurrentQuestionsIndex() >= rows.length - 1}
          >
            Next
          </button>
        </div>

        {user.rolename.toLowerCase() === dataReviewUser && (
          <EntryFeedBackField
            state={state}
            setState={setState}
            onSave={onSave}
          />
        )}
      </div>
    </div>
  );
};

export default QuestionDetail;
