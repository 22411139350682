import { uuid } from "uuidv4";

export const englishPaper10 = () => {
  return {
    instruction: {
      objective_note:
        "You have four choices for each objective type question as A, B, C and D. The choice which you think is correct; fill that circle in front of that question number. Use marker or pen to fill the circles. Cutting or filling two or more circles will result in zero mark in that question.",
      objective_marks: "19",
      objective_time: "20 Minutes",
      subjective_time: "2:10 Hours",
      subjective_marks: "56",
    },
    short_question: {
      note: "Sections 'I' and 'II' comprises pages no. 3-5 and questions therein are to be answered on the separately provided answer book. Answer all the questions from section 'I' and section 'II'. Use supplementary answer sheet i.e. sheet B if required. Write your answers neatly and legibly.",
      marks: "",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer any FIVE of the following parts. Each part carries equal marks.",
          marks: "(10)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Translate into Urdu OR Re-write into simple English the following paragraph.",
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Write an essay of 150-200 words on any ONE of the following topics:",
          marks: "(15)",
          ui_style: "punjab_10_english_essay_writing_and_para",
        },
        {
          id: uuid(),
          note: "Change any FIVE of the following sentences into indirect form.",
          marks: "(05)",
          ui_style: "single_line_bullet",
        },
        {
          id: uuid(),
          note: "Use any FIVE of the following pair of words in sentences.",
          ui_style: "bullet",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Translate the following paragraph into English.",
          marks: "(08)",
          ui_style: "punjab_10_translation_with_ten_sentences",
        },
      ],
    },
  };
};

export const englishPaper9 = () => {
  return {
    instruction: {
      objective_note:
        "You have four choices for each objective type question as A, B, C and D. The choice which you think is correct; fill that circle in front of that question number. Use marker or pen to fill the circles. Cutting or filling two or more circles will result in zero mark in that question.",
      objective_marks: "19",
      objective_time: "20 Minutes",
      subjective_time: "2:10 Hours",
      subjective_marks: "56",
    },
    short_question: {
      note: "Sections 'I' and 'II' comprises pages no. 3-5 and questions therein are to be answered on the separately provided answer book. Answer all the questions from section 'I' and section 'II'. Use supplementary answer sheet i.e. sheet B if required. Write your answers neatly and legibly.",
      marks: "",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer any FIVE of the following parts. Each part carries equal marks.",
          marks: "(10)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Translate any TWO of the following paragraphs into Urdu.",
          marks: "(04+04)",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Use any FIVE of the following words/phrases/idioms in your sentences:",
          ui_style: "bullet",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "Read the following passage carefully and answer the questions given at the end.",
          marks: "(10)",
        },
        {
          id: uuid(),
          note: "Translate any FIVE of the following sentences into English:",
          ui_style: "rtl_row_2_bullet",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Change the voice of the following.",
          ui_style: "single_line_bullet",
          marks: "(05)",
        },
      ],
    },
  };
};

export const mathPaper = () => {
  let shortQuestion = [1, 2, 3];
  let longQuestion = [1, 2, 3, 4, 5];

  return {
    instruction: {
      objective_note:
        "You have four choices for each objective type question as A, B, C and D. The choice which you think is correct; fill that circle in front of that question number. Use marker or pen to fill the circles. Cutting or filling two or more circles will result in zero mark in that question.",
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2:10 Hours",
      subjective_marks: "60",
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: "(2 × 18 = 36)",
      questions: shortQuestion.map((ele) => ({
        id: uuid(),
        note: "Write answers of any six of the following short questions.",
        marks_disabled: false,
      })),
    },
    long_question: {
      note: "Attempt any THREE questions. But question No. 9 is compulsory.",
      marks: "(3 × 8 = 24)",
      questions: longQuestion.map((ele, index) => ({
        id: uuid(),
        marks_disabled: false,
        note_disabled: true,
        ui_style:
          index + 1 == 5 ? "math_long_q9_include_or_and_single_marks" : null,
      })),
    },
  };
};

export const defaultPaper = () => {
  let shortQuestion = [1, 2, 3];
  let longQuestion = [1, 2, 3];

  return {
    instruction: {
      objective_note:
        "You have four choices for each objective type question as A, B, C and D. The choice which you think is correct; fill that circle in front of that question number. Use marker or pen to fill the circles. Cutting or filling two or more circles will result in zero mark in that question.",
      objective_marks: "12",
      objective_time: "15 Minutes",
      subjective_time: "1:45 Hours",
      subjective_marks: "48",
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: "",
      marks_disabled: false,
      questions: shortQuestion.map((ele) => ({
        id: uuid(),
        note: "Answer any FIVE parts from the following:",
        marks: "10",
      })),
    },
    long_question: {
      note: "Attempt any TWO questions.",
      marks: "(9 × 2 = 18)",
      questions: longQuestion.map((ele) => ({
        id: uuid(),
        note_disabled: true,
        marks_disabled: false,
      })),
    },
  };
};

// Sindh Board
export const englishSindhPaper9 = () => {
  return {
    instruction: {
      objective_note: "Choose an appropriate answer from the options provided.",
      objective_marks: "20",
      objective_time: "30 minutes",
      subjective_time: "2 hours 30 minutes",
      subjective_marks: "80",
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: "40",
      questions: [
        {
          id: uuid(),
          note: "Answer any FIVE of the following questions in three to four sentences each.",
          marks: "(10)",
          ui_style: "list",
        },
        {
          id: uuid(),
          note: "Do as directed.",
          marks: "(20)",
          ui_style: "do_as_directed",
        },
        {
          id: uuid(),
          note: "Indicate the part of speech of the underlined words.",
          marks: "(05)",
          ui_style: "list",
        },
        {
          id: uuid(),
          note: "Translate the following paragraph into Urdu/Sindhi.",
          marks: "(05)",
          ui_style: "none",
        },
      ],
    },
    long_question: {
      note: "Answer all the questions of this section.",
      marks: "40",
      questions: [
        {
          id: uuid(),
          note: "Read the given passage and fill in the blanks from the options provided in the box.",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Write an essay of 100 to 200 words on any ONE of the following topics.",
          marks: "(10)",
          ui_style: "single_line_bullet",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(10)",
        },
        {
          id: uuid(),
          note: "Read the following passage and answer the questions given below.",
          marks: "(15)",
        },
      ],
    },
  };
};
export const englishSindhPaper10 = () => {
  return {
    instruction: {
      objective_note: "Choose an appropriate answer from the options provided.",
      objective_marks: "20",
      objective_time: "30 minutes",
      subjective_time: "2:30 Hours",
      subjective_marks: "80",
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: "40",
      questions: [
        {
          id: uuid(),
          note: "Answer any FIVE of the following questions in three to four sentences each.",
          marks: "(10)",
          ui_style: "list",
        },
        {
          id: uuid(),
          note: "Do as directed.",
          marks: "(20)",
          ui_style: "do_as_directed",
        },
        {
          id: uuid(),
          note: "Indicate the kind of part of speech of the underlined words for instance Adverb.",
          marks: "(05)",
          ui_style: "list",
        },
        {
          id: uuid(),
          note: "Translate the following paragraph into Urdu/Sindhi.",
          marks: "(05)",
          ui_style: "none",
        },
      ],
    },
    long_question: {
      note: "Answer all the questions of this section.",
      marks: "40",
      questions: [
        {
          id: uuid(),
          note: "Read the given passage and fill in the blanks from the options provided in the box.",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Write an essay of 100 to 200 words on any ONE of the following topics.  ",
          marks: "(10)",
          ui_style: "sindh_10_english_essay_writing",
        },
        {
          id: uuid(),
          note: "",
          note_disabled: true,
          marks: "(10)",
        },
        {
          id: uuid(),
          note: "Read the following passage and answer the questions given below.",
          marks: "(15)",
        },
      ],
    },
  };
};

export const computerStudiesSindhPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Choose the correct Answer for each from the given question:",
      objective_marks: "12",
      objective_time: "20 Minutes",
      subjective_time: "2:40 Hours",
      subjective_marks: "48",
    },
    short_question: {
      note: "Answer any Eight (08) questions from this section. Each carries 03 Marks.",
      marks: "24",
      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "",
        },
      ],
    },
    long_question: {
      note: "Attempt any FOUR (04) questions. Each carries 6 Marks.",
      note_disabled: true,
      marks: "24",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "6",
        },
      ],
    },
  };
};
export const pakistanStudiesSindhPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Choose the correct answer for each from the given options.",
      objective_marks: "15",
      objective_time: "1/2 Hours",
      subjective_time: "2 ½ hours",
      subjective_marks: "60",
    },
    short_question: {
      note: "Attempt any SIX questions from this section. All questions carry equal marks.",
      marks: "30",
      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "5",
        },
      ],
    },
    long_question: {
      note: " Answer any THREE questions from this section. All questions carry equal marks.",
      note_disabled: true,
      marks: "30",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "10",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "10",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "10",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "10",
        },
        {
          id: uuid(),
          note: "Write a detailed note on any ONE of the following:",
          ui_style: "lq_bullets",
          marks: "10",
        },
      ],
    },
  };
};

export const urduSindhPaper9 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "دیے ہوئے ممکنہ جوابات میں  سے ہر ایک کے لیے دُرست جواب  کا انتخاب  کیجیے۔",
      objective_marks: "۱۵",
      objective_time: "۲۰ منٹ",
      subjective_time: "۲ گھنٹے ۴۰ منٹ",
      subjective_marks: "۶۰",
    },
    short_question: {
      marks: "۳۰",
      note: "یہ پرچہ جو مختصر جواب کے سوالات (حصہ ب) اور بیانیہ جوابات کے سوالات (حصہ ج) پر مشتمل ہے ،۳۰ منٹ کے بعد دیا جائے گا اور اس کا کُل دورانیہ صرف ۲ گھنٹے ۴۰ منٹ ہے۔",
      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "مندرجہ ذیل سوالات میں سے کسی دو  کے مختصر  جوابات تحریر کیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے کسی ایک سبق کا  مرکزی خیال ۔۔۔یا۔۔۔ خلاصہ مصنف کے حوالے کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet",
          marks: "(۵)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل  میں سے کسی ایک نظم کا  مرکزی خیال۔۔۔یا۔۔۔خلاصہ شاعر کے حوالے کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet",
          marks: "(۵)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے دو شعر ی اصطلاحات کی تعریف مثالوں کے ساتھ کیجیے۔",
          ui_style: "noNumberBullet",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذيل ميں سے دو اصناف ادب کی تغريف مختصرا کيجيے",
          ui_style: "یا",
          // ui_style: "bullet",
          marks: "",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے  کسی دو کی  تعریف مثالوں کے ساتھ کیجیے۔",
          ui_style: "",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذيل الفاظ کے مترادف لکھيے",
          ui_style: "یا",
          // ui_style: "bullet",
          marks: "",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے چار الفاظ و محاورات اپنے جملوں میں استعمال کیجیے۔",
          ui_style: "",
          marks: "",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: " سیاق و سباق کے حوالے کے ساتھ مندرجہ ذیل عبارت کی تشریح سلیس اور با محاورہ زبان میں کیجیے۔",
          ui_style: "",
          marks: "(۸)",
          or: false,
        },
        {
          id: uuid(),
          note: "سیاق و سباق کے حوالوں کے ساتھ مندرجہ ذیل میں سے کسی دو جملوں کی تشریح کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "(۶)",
          or: false,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل اشعار میں سے کسی دو کی تشریح شاعر کے مختصر تعارف کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۶)",
          or: false,
        },
        {
          id: uuid(),
          note: "نظم کے عنوان اور شاعر کے حوالے کے ساتھ مندرجہ ذیل جُز وکی تشریح کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "درج ذیل عنوانات میں سے کسی ایک عنوان  پر  کم از کم  دو صفحات  پر مشتمل مضمون لکھیے۔",
          ui_style: "bullet_number",
          marks: "(۱۰)",
          or: false,
        },
        {
          id: uuid(),
          note: "درج ذيل میں سے کسی ایک شخصیت کے حالاتِ زندگی اور ادبی کارناموں کا ذکر کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
      ],
    },
  };
};
export const urduSindhPaper10 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "دیے ہوئے ممکنہ جوابات میں  سے ہر ایک کے لیے دُرست جواب  کا انتخاب  کیجیے۔",
      objective_marks: "۱۵",
      objective_time: "۲۰ منٹ",
      subjective_time: "۲ گھنٹے ۴۰ منٹ",
      subjective_marks: "۶۰",
    },
    short_question: {
      note: "یہ پرچہ جو مختصر جواب کے سوالات (حصہ ب) اور بیانیہ جوابات کے سوالات (حصہ ج) پر مشتمل ہے ،۳۰ من�� کے بعد دیا جائے گا اور اس کا کُل دورانیہ صرف ۲ گھنٹے ۴۰ منٹ ہے۔",
      marks: "۳۰",

      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "مندرجہ ذیل سوالات میں سے کسی دو  کے مختصر  جوابات تحریر کیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے کسی ایک سبق کا  مرکزی خیال ۔۔۔یا۔۔۔ خلاصہ مصنف کے حوالے کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet_romen_line",
          marks: "(۵)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل  میں سے کسی ایک نظم کا  مرکزی خیال۔۔۔یا۔۔۔خلاصہ شاعر کے حوالے کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet_romen_line",
          marks: "(۵)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے دو شعر ی اصطلاحات کی تعریف مثالوں کے ساتھ کیجیے۔",
          ui_style: "",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذيل ميں سے دو اصناف ادب کی تغريف مختصرا کيجيے",
          ui_style: "یا",
          // ui_style: "bullet",
          marks: "(۴+۴)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سے  کسی دو کی  تعریف مثالوں کے ساتھ کیجیے۔",
          ui_style: "",
          marks: "(۶)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذيل الفاظ کے مترادف لکھيے",
          ui_style: "یا",
          // ui_style: "bullet",
          marks: "(۴+۴)",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے چار الفاظ و محاورات اپنے جملوں میں استعمال کیجیے۔",
          ui_style: "bullet",
          marks: "(۴+۴)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: " سیاق و سباق کے حوالے کے ساتھ مندرجہ ذیل عبارت کی تشریح سلیس اور با محاورہ زبان میں کیجیے۔",
          ui_style: "",
          marks: "(۸)",
          or: false,
        },
        {
          id: uuid(),
          note: "سیاق و سباق کے حوالوں کے ساتھ مندرجہ ذیل میں سے کسی دو جملوں کی تشریح کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "(۶)",
          or: false,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل اشعار میں سے کسی دو کی تشریح شاعر کے مختصر تعارف کے ساتھ تحریر کیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۶)",
          or: false,
        },
        {
          id: uuid(),
          note: "نظم کے عنوان اور شاعر کے حوالے کے ساتھ مندرجہ ذیل جُز وکی تشریح کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
        {
          id: uuid(),
          note: "درج ذیل عنوانات میں سے کسی ایک عنوان  پر  کم از کم  دو صفحات  پر مشتمل مضمون لکھیے۔",
          ui_style: "bullet_number",
          marks: "(۱۰)",
          or: false,
        },
        {
          id: uuid(),
          note: "090 يا 070 میں سے کسی ایک شخصیت کے حالاتِ زندگی اور ادبی کارناموں کا ذکر کیجیے۔",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
          or: true,
        },
      ],
    },
  };
};

// export const urduSindhPaper = (class_id) => {
//   return {
//     instruction: {
//       objective_note:
//         "دیے ہوئے ممکنہ جوابات میں  سے ہر ایک کے لیے دُرست جواب  کا انتخاب  کیجیے۔",
//       objective_marks: "۱۵",
//       objective_time: "۲۰ منٹ",
//       subjective_time: "۲ گھنٹے ۴۰ ��نٹ",
//       subjective_marks: "۶۰",
//     },
//     short_question: {
//       note: "یہ پرچہ جو مختصر جواب کے سوالات (حصہ ب) اور بیانیہ جوابات کے سوالات (حصہ ج) پر مشتمل ہے ،۳۰ منٹ کے بعد دیا جائے گا اور اس کا کُل دورانیہ صرف ۲ گھنٹے ۴۰ منٹ ہے۔",
//       marks: "۳۰",

//       note_disabled: true,
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: "مندرجہ ذیل سوالات میں سے کسی دو  کے مختصر  جوابات تحریر کیجیے۔",
//           ui_style: "bullet_romen",
//           marks: "(۶)",
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "bullet_romen_line",
//           marks: "(۵)",
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "bullet_romen_line",
//           marks: "(۵)",
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "",
//           marks: "(۶)",
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "bullet",
//           marks: "(۴+۴)",
//         },
//       ],
//     },
//     long_question: {
//       note: "",
//       note_disabled: true,
//       marks: "۳۰",
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: " سیاق و سباق کے حوالے کے ساتھ مندرجہ ذیل عبارت کی تشریح سلیس اور با محاورہ زبان میں کیجیے۔",
//           ui_style: "",
//           marks: "(۸)",
//           or: false,
//         },
//         {
//           id: uuid(),
//           note: "سیاق و سباق کے حوالوں کے ساتھ مندرجہ ذیل میں سے کسی دو جملوں کی تشریح کیجیے۔",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//           or: true,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "",
//           marks: "(۶)",
//           or: false,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//           or: true,
//         },
//         {
//           id: uuid(),
//           note: "مندرجہ ذیل اشعار میں سے کسی دو کی تشریح شاعر کے مختصر ��عارف کے ساتھ تحریر کیجیے۔",
//           ui_style: "bullet_romen",
//           marks: "(۶)",
//           or: false,
//         },
//         {
//           id: uuid(),
//           note: "نظم کے عنوان اور شاعر کے حوالے کے ساتھ مندرجہ ذیل جُز وکی تشریح کیجیے۔",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//           or: true,
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل عنوانات میں سے کسی ایک عنوان  پر  کم از کم  دو صفحات  پر مشتمل مضمون لکھیے۔",
//           ui_style: "bullet_number",
//           marks: "(۱۰)",
//           or: false,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//           or: true,
//         },
//       ],
//     },
//   };
// };

export const urduPunjabaper9 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "ہر سوال کے چار ممکنہ جوابات A, B, C, D دیے گئے ہیں۔ جوابی کاپی پر ہر سوال کے سامنے دیے گئے دائروں میں سے درست جواب کے مطابق متعلقہ دائرہ کو مار کر یا پین سے بھر دیجیے ۔ ایک سے زیادہ دائروں کو پر کرنے یا کاٹ کر پر کرنے کی صورت میں مذکورہ جواب غلط تصور ہوگا ",
      objective_marks: "15",
      objective_time: "20 منٹ",
      subjective_time: "2 گھنٹے 10منٹ",
      subjective_marks: "60",
    },
    short_question: {
      note: "درج ذیل نظم و غزل کے اشعار کی تشریح کیجیے۔",
      marks: "10",

      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "حصہ نظم",
          ui_style: "bullet_romen",
          marks: "",
        },
        {
          id: uuid(),
          note: "حصہ غزل",
          ui_style: "bullet_romen",
          marks: "",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذیل  نثر پاروں کی تشریح کیجیے ۔سبق کا عنوان مصنف کا نام اور خط کشیدہ الفاظ کے معنی بھی لکھیے۔",
          ui_style: "bullet",
          marks: "(5,5)",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے کوئی سے پانچ سوالات کے مختصر جوابات لکھیے ۔",
          ui_style: "bullet_romen_line",
          marks: "(10)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "کسی ایک سبق کا  خلاصہ لکھیے۔",
          ui_style: "bullet_romen_line",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "نظم ’’ 000 ‘‘ کا مرکزی خیال/خلاصہ اور شاعر کا نام لکھیے۔",
          // note: "",
          ui_style: "",
          marks: "(05)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "(10)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "(05)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "",
          ui_style: "یا",
          marks: "",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "درج ذیل جملوں کی اصلاح کیجیے۔",
          ui_style: "bullet_romen_3line",
          marks: "(05)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "درج ذیل جملوں کی تکمیل کریں۔",
          ui_style: "bullet_romen_3line",
          marks: "",
          marks_disabled: true,
        },
      ],
    },
  };
};

// export const urduPunjabaper9 = (class_id) => {
//   return {
//     instruction: {
//       objective_note:
//         "ہر سوال کے چار ممکنہ جوابات A, B, C, D دیے گئے ہیں۔ جوابی کاپی پر ہر سوال کے سامنے دیے گئے دائروں میں سے درست جواب کے مطابق متعلقہ دائرہ کو مار کر یا پین سے بھر دیجیے ۔ ایک سے زیادہ دائروں کو پر کرنے یا کاٹ کر پر کرنے کی صورت میں مذکورہ جواب غلط تصور ہوگا ",
//       objective_marks: "15",
//       objective_time: "20 منٹ",
//       subjective_time: "2 گھنٹے 10منٹ",
//       subjective_marks: "60",
//     },
//     short_question: {
//       note: "درج ذیل نظم و غزل کے اشعار کی تشریح کیجیے۔(تین اشعار حصہ نظم سے اور دو اشعار حصہ غزل سے)",
//       marks: "10",

//       note_disabled: true,
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: "حصہ نظم",
//           ui_style: "bullet_romen",
//           marks: "",
//         },
//         {
//           id: uuid(),
//           note: "حصہ غزل",
//           ui_style: "bullet_romen",
//           marks: "",
//         },
//       ],
//     },
//     long_question: {
//       note: "",
//       note_disabled: true,
//       marks: "۳۰",
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: "درج ذیل  نثر پاروں کی تشریح کیجیے ۔سبق کا عنوان مصنف کا نام اور خط کشیدہ الفاظ کے معنی بھی لکھیے۔",
//           ui_style: "bullet",
//           marks: "(5,5)",
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل میں سے کوئی سے پانچ سوالات کے مختصر جوابات لکھیے ۔",
//           ui_style: "bullet_romen_line",
//           marks: "(10)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "کسی ایک سبق کا  خلاصہ لکھیے۔",
//           ui_style: "bullet_romen_line",
//           marks: "(05)",
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "",
//           marks: "(05)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "",
//           marks: "(10)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "",
//           marks: "(05)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "",
//           ui_style: "یا",
//           marks: "",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل جملوں کی اصلاح کیجیے۔",
//           ui_style: "bullet_romen_3line",
//           marks: "(05)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل جملوں کی تکمیل کریں۔",
//           ui_style: "bullet_romen_3line",
//           marks: "",
//           marks_disabled: true,
//         },
//       ],
//     },
//   };
// };

export const urduPunjabaper10 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "ہر سوال کے چار ممکنہ جوابات A, B, C, D دیے گئے ہیں۔ جوابی کاپی پر ہر سوال کے سامنے دیے گئے دائروں میں سے درست جواب کے مطابق متعلقہ دائرہ کو مار کر یا پین سے بھر دیجیے ۔ ایک سے زیادہ دائروں کو پر کرنے یا کاٹ کر پر کرنے کی صورت میں مذکورہ جواب غلط تصور ہوگا ",
      objective_marks: "15",
      objective_time: "20 منٹ",
      subjective_time: "2 گھنٹے 10منٹ",
      subjective_marks: "60",
    },
    short_question: {
      note: "درج ذیل نظم و غزل کے اشعار کی تشریح کیجیے۔ ",
      marks: "(10)",

      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "حصہ نظم",
          ui_style: "bullet",
          marks: "",
        },
        {
          id: uuid(),
          note: "حصہ غزل",
          ui_style: "bullet",
          marks: "",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذیل  نثر پاروں کی تشریح کیجیے ۔سبق کا عنوان مصنف کا نام اور خط کشیدہ الفاظ کے معنی بھی لکھیے۔",
          ui_style: "bullet",
          marks: "10=5+5=1+1+1+2",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے کوئی سے پانچ سوالات کے مختصر جوابات لکھیے ۔",
          ui_style: "bullet_romen",
          marks: "(10)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "کسی ایک سبق کا  خلاصہ لکھیے۔",
          ui_style: "bullet_romen_line",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "درج ذیل موضوعات میں سے کسی ایک موضو ع پر مضمون لکھیے۔",
          ui_style: "bullet_romen_3line",
          marks: "(15)",
          marks_disabled: true,
        },
        {
          id: uuid(),
          note: "درج ذیل عبارت کو غور سے پڑھیے اور آخر میں دیے گئے سوالات کے جوابات لکھیے۔",
          ui_style: "",
          marks: "(10)",
          marks_disabled: true,
        },
      ],
    },
  };
};

// export const urduPunjabaper10 = (class_id) => {
//   return {
//     instruction: {
//       objective_note:
//         "ہر سوال کے چار ممکنہ جوابات A, B, C, D دیے گئے ہیں۔ جوابی کاپی پر ہر سوال کے سامنے دیے گئے دائروں میں سے درست جواب کے مطابق متعلقہ دائرہ کو مار کر یا پین سے بھر دیجیے ۔ ایک سے زیادہ دائروں کو پر کرنے یا کاٹ کر پر کرنے کی صورت میں مذکورہ جواب غلط تصور ہوگا ",
//       objective_marks: "15",
//       objective_time: "20 منٹ",
//       subjective_time: "2 گھنٹے 10منٹ",
//       subjective_marks: "60",
//     },
//     short_question: {
//       note: "درج ذیل نظم و غزل کے اشعار کی تشریح کیجیے۔ تین اشعار حصہ نظم سے اور دو اشعار حصہ غزل سے",
//       marks: "(10)",

//       note_disabled: true,
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: "حصہ نظم",
//           ui_style: "bullet",
//           marks: "",
//         },
//         {
//           id: uuid(),
//           note: "حصہ غزل",
//           ui_style: "bullet",
//           marks: "",
//         },
//       ],
//     },
//     long_question: {
//       note: "",
//       note_disabled: true,
//       marks: "",
//       marks_disabled: false,
//       questions: [
//         {
//           id: uuid(),
//           note: "درج ذیل نثر پاروں کی تشریح کیجیے۔حوالہ ٔمتن ، خط کشیدہ الفاظ کے معنی اور سبق کا سیاق و سباق بھی درج کیجیے۔",
//           ui_style: "bullet",
//           marks: "10=5+5=1+1+1+2",
//         },
//         {
//           id: uuid(),
//           note: "مندرجہ ذیل میں سے کوئی سے پانچ سوالات کے مختصر جوابات دیجیے ۔",
//           ui_style: "bullet_romen",
//           marks: "(10)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل میں سے کسی ایک سبق کا خلاصہ تحریر کیجیے۔",
//           ui_style: "bullet_romen_line",
//           marks: "(05)",
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل موضوعات میں سے کسی ایک موضو ع پر مضمون لکھیے۔",
//           ui_style: "bullet_romen_3line",
//           marks: "(15)",
//           marks_disabled: true,
//         },
//         {
//           id: uuid(),
//           note: "درج ذیل عبارت کو غور سے پڑھیے اور آخر میں دیے گئے سوالات کے جوابات لکھیے۔",
//           ui_style: "",
//           marks: "(10)",
//           marks_disabled: true,
//         },
//       ],
//     },
//   };
// };
export const tadreesQuranPunjabaper = (class_id) => {
  return {
    instruction: {
      objective_note: "درست جواب کی نِشان دِہی کیجیے۔",
      objective_marks: "۱۰",
      objective_time: "۱۵منٹ",
      subjective_time: "1 گھنٹا45 منٹ",
      subjective_marks: "40",
    },
    short_question: {
      note: "",
      marks: "",

      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "کوئی سےپانچ سوالات کےمختصرجوابات دیجیے۔",
          ui_style: "bullet_romen",
          marks: "(02x5=10)",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سےکوئی سے پانچ قرآنی اَلفاظ کےمعانی لکھیے",
          ui_style: "",
          marks: "(5x1=05)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "",
      marks_disabled: true,
      questions: [
        {
          id: uuid(),
          note: "درج ذیل میں سےکوئی سے تین اَجزاکابامحاورہ ترجمہ لکھیے",
          ui_style: "bullet_romen",
          marks: "(3x5=15)",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سےکسی ایک موضوع پرنوٹ لکھیے",
          ui_style: "bullet_romen",
          marks: "(10)",
        },
      ],
    },
  };
};

export const islPunjabPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "دیےہوئےممکنہ جوابات میں سےہرایک کےلیےدُرست جواب کاانتخاب کیجیے",
      objective_marks: "۱۵",
      objective_time: "آدھاگھنٹا",
      subjective_time: "ڈھائی گھنٹے",
      subjective_marks: "۶۰",
    },
    short_question: {
      note: "یہ پرچہ جومختصرجواب کےسوالات(حصّہ ب)اوربیانیہ جوابات کےسوالات(حصّہ ج)پرمشتمل ہے،۳۰منٹ کےبعددِیاجائےگااوراس کاکُل دورانیہ صرف ڈھائی گھنٹےہے۔",
      marks: "۳۰",
      note_disabled: false,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذيل ميں سے چھے سولات کے مختصر جوابات تحرير کيجيے",
          ui_style: "bullet_romen",
          marks: "۱۲",
        },
        {
          id: uuid(),
          note: "درج ذيل ميں سے چھے سولات کے مختصر جوابات تحرير کيجيے",
          ui_style: "bullet_romen",
          marks: "۱۲",
        },
        {
          id: uuid(),
          note: "درج ذيل ميں سے چھے سولات کے مختصر جوابات تحرير کيجيے",
          ui_style: "bullet_romen",
          marks: "۱۶",
        }
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذيل ميں سے کوئی سی دو احاديث مبارکہ کا اردو ترجمہ کيجيے",
          ui_style: "bullet_romen",
          marks: "۵ + ۵ = ۱۰",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے کوئی سی دو شخصیات پر مختصر نوٹ لکھیں۔",
          ui_style: "bullet_romen",
          marks: "۵ + ۵ = ۱۰",
        },
        {
          id: uuid(),
          note: "درج ذیل میں سے کوئی سے دو سوالات کے تفصیلی جوابات تحریر کیجیے۔",
          ui_style: "bullet_romen",
          marks: "۱۰ + ۱۰ = ۲۰",
        },
      ],
    },
  };
};

export const islamiyatSindhaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "دیےہوئےممکنہ جوابات میں سےہرایک کےلیےدُرست جواب کاانتخاب کیجیے",
      objective_marks: "۱۵",
      objective_time: "آدھاگھنٹا",
      subjective_time: "ڈھائی گھنٹے",
      subjective_marks: "۶۰",
    },
    short_question: {
      note: "یہ پرچہ جومختصرجواب کےسوالات(حصّہ ب)اوربیانیہ جوابات کےسوالات(حصّہ ج)پرمشتمل ہے،۳۰منٹ کےبعددِیاجائےگااوراس کاکُل دورانیہ صرف ڈھائی گھنٹےہے۔",
      marks: "۳۰",
      note_disabled: false,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "مندرجہ ذیل قرآنی آیات میں سے کسی دو کاصرف ترجمہ تحریرکیجیے۔",
          ui_style: "bullet_romen",
          marks: "۶",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل  اَحادیثِ نبوی   میں سے  کسی دو کاصرف ترجمہ تحریرکیجیے۔",
          ui_style: "bullet_romen",
          marks: "۶",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "۲",
        },
        {
          id: uuid(),
          note: "ایک جملےمیں تعریف کیجیے",
          ui_style: "bullet_romen",
          marks: "۲",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "مندرجہ ذیل قرآنی آیات میں کسی ایک کاترجمہ اورتشریح تحریرکیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۴+۳)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل اَحادیث میں کسی ایک کاترجمہ اورتشریح  تحریرکیجیے۔",
          ui_style: "bullet_romen",
          marks: "(۴+۳)",
        },
        {
          id: uuid(),
          note: "مندرجہ ذيل ميں سے کسی بھی دو سوالات کے جوابات تحرير کيجيے۔",
          ui_style: "",
          marks: "",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "",
        },
        {
          id: uuid(),
          note: "مندرجہ ذیل میں سےکسی ایک پرنوٹ تحریرکیجیے",
          ui_style: "bullet",
          marks: "",
        },
      ],
    },
  };
};

export const sindhiSindhaper = (class_id) => {
  return {
    instruction: {
      objective_note: "هيٺ ڏنل هر هڪ سوال ��اءِ صحيح جواب چونڊيو.",
      objective_marks: "15",
      objective_time: " 30 منٽ",
      subjective_time: "اڍائي ڪلاڪ",
      subjective_marks: "60",
    },
    short_question: {
      note: "هي پيپر مختصر جواب جي سوالن (حصو ”ب“) ۽ بياني جواب جي سوالن (حصو ”ج“ تي مشتمل آهي ۽ اڌ ڪلاڪ کانپوءِ ڏنو ويندو. ان جو ڪل وقت اڍائي ڪلڪ آهي.",
      marks: "30",
      note_disabled: false,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "ڪن به پنجن سوالن جا جواب لکو.",
          ui_style: "bullet_romen",
          marks: "10",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "4",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_alif",
          marks: "4+3+3+6",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "30",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "5",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "5",
        },
        {
          id: uuid(),
          note: "هيٺ ڏنل شعرن مان ڪن به ٻن جي تشريح شاعر ۽ نظم جي حوالي سان ڪريو",
          ui_style: "bullet_romen",
          marks: "5",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_alif",
          marks: "4+4",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_alif",
          marks: "4+3",
        },
      ],
    },
  };
};

export const defaultSindhPaper = ({
  paper_time,
  paper_marks,
  objective_marks,
  objective_time,
  subjective_time,
  subjective_marks,
  short_question_0_note,
  short_question_marks,
  long_question_0_note,
  long_question_marks,
}) => {
  return {
    instruction: {
      paper_time: paper_time || "",
      paper_marks: paper_marks || "",
      objective_note:
        "Choose the correct answer for each from the given options:",
      objective_marks,
      objective_time,
      subjective_time,
      subjective_marks,
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: short_question_marks,
      questions: [
        {
          id: uuid(),
          note: short_question_0_note,
          marks: "",
          marks_disabled: false,
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: long_question_marks,
      questions: [
        {
          id: uuid(),
          note: long_question_0_note,
          marks: "",
          marks_disabled: false,
        },
      ],
    },
  };
};

// punjab
export const pakStudiesPunjabPaper9 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "You have four choices for each objective type question as A, B, C and D. The choice which you think is correct; fill that circle in front of that question number. Use marker or pen to fill the circles. Cutting or filling two or more circles will result in zero mark in that question. Attempt as many questions as given in objective type question paper and leave others blank. Write the letter A, B, C or D in the column (write correct option) against each question. If there is a contradiction in the bubble and hand-written answer, bubbled option will be considered correct.",
      objective_marks: "10",
      objective_time: "15 Minutes",
      subjective_time: "1 Hour 45 Minutes",
      subjective_marks: "40",
    },
    short_question: {
      note: "",
      marks: "24",
      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer briefly any six questions.",
          ui_style: "bullet",
          marks: "12",
        },
        {
          id: uuid(),
          note: "Answer any six questions briefly.",
          ui_style: "bullet",
          marks: "12",
        },
      ],
    },
    long_question: {
      note: "Attempt any two questions. All questions carry equal marks.",
      note_disabled: true,
      marks: "16",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "08",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "08",
        },
        {
          id: uuid(),
          note: "Write short notes on:",
          ui_style: class_id == "2" ? "bullet" : "rtl_row_2_bullet",
          marks: "(04 + 04)",
        },
      ],
    },
  };
};
export const computerStudiesPunjabPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        " Four possible answers A, B, C and D to each question are given. The choice which you think is correct, fill that circle in front of the question with Marker or Pen Ink in the answer book. Cutting or filling two or more circles will result in zero mark in that question.",
      objective_marks: "10",
      objective_time: "15 Minutes",
      subjective_time: "1:45 Hours",
      subjective_marks: "40",
    },
    short_question: {
      note: "",
      marks: "24",
      note_disabled: true,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Write Short answers to any FOUR (4) questions:",
          ui_style: "bullet",
          marks: "8",
        },
        {
          id: uuid(),
          note: "Write Short answers to any FOUR (4) questions:",
          ui_style: "bullet",
          marks: "8",
        },
        {
          id: uuid(),
          note: "Write Short answers to any FOUR (4) questions:",
          ui_style: "bullet",
          marks: "8",
        },
      ],
    },
    long_question: {
      note: "Attempt any TWO (2) questions.",
      note_disabled: true,
      marks: "16",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "08",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "08",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "08",
        },
      ],
    },
  };
};

export const islamiyatPunjabPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "درتس وجاب اک ااختنب ے",
      objective_marks: "نمبر20",
      objective_time: "آدھاگھنٹا",
      subjective_time: "ڈھائی گھنٹے",
      subjective_marks: "۶۰",
    },
    short_question: {
      note: "درج ذلی ںیم ےسےھچ وساالت ےک رصتخموجاابت دےیجی ۔",
      marks: "۳۰",
      note_disabled: false,
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذلی ںیم ےسےھچ وساالت ےک رصتخموجاابت دےیجی ۔",
          ui_style: "bullet_romen",
          marks: "(12=2x6)",
        },
        {
          id: uuid(),
          note: "درج ذلی ںیم ےسےھچ وساالت ےک رصتخموجاابت دےیجی ۔",
          ui_style: "bullet_romen",
          marks: "(12=2x6)",
        },
        {
          id: uuid(),
          note: "درج ذلی ںیم ےسےھچ وساالت ےک رصتخموجاابت دےیجی ۔",
          ui_style: "",
          marks: "(16=2x8)",
        },
        
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "۳۰",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "درج ذلی ںیم ےس وکیئ یس دو ا احدثیِ ابمرہک اک ا ردو رتہمج ےیجیک :",
          ui_style: "bullet_romen",
          marks: "(10=5+5)",
        },
        {
          id: uuid(),
          note: "درج ذلی ںیم ےس وکیئ یس دو ایصخشت رپ رصتخم ونٹ ںیھکل:",
          ui_style: "bullet_romen",
          marks: "(10=5+5)",
        },
        {
          id: uuid(),
          note: "درج ذلی ںیم ےس وکیئ ےس دو وساالت ےک یلیصفت وجاب دےیجی:",
          ui_style: "",
          marks: "(20=10+10)",
        }
      ],
    },
  };
};
// kpk board
export const biologyKPKPaper10 = () => {
  return {
    instruction: {
      objective_note:
        "There are three sections of this paper, i.e. Section A, B and C. Attempt Section-A on this sheet and return it to the Superintendent within the given time. No marks will be awarded for Cutting, Erasing or Overwriting. Marks of identification will lead to UFM case. Mobile Phones, etc are not allowed in the Examination Hall.",
      objective_marks: "12",
      objective_time: "15 Minutes",
      subjective_time: "2 Hours and 45 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "Time allowed Section B and Section C is 2 Hours and 45 Minutes.",
      note_disabled: true,
      marks: "32",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer any EIGHT parts. Each part carries FOUR marks.",
          ui_style: "bullet",
          marks: "32",
        },
      ],
    },
    long_question: {
      note: "Attempt any three of the following questions. All questions carry equal marks.",
      note_disabled: true,
      marks: "21",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(04+03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(04+03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(04+03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(04+03)",
        },
      ],
    },
  };
};

export const mathKPKPaper9 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Four circles are given with every question. Only fill the circle with correct answer. ",
      note_disabled: true,
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2 Hours 40 Minutes",
      subjective_marks: "60",
    },
    short_question: {
      note: "Time allowed Section B and Section C is 2 Hours and 40 Minutes.",
      note_disabled: true,
      marks: "36",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note:
            class_id == "2"
              ? "Attempt any NINE of the following short questions. Each question carries 4 marks."
              : "Attempt any 9 of the following.",
          ui_style: "bullet_roman",
          marks: "36",
        },
      ],
    },
    long_question: {
      note:
        class_id == "2"
          ? "Attempt any THREE of the following questions. Each question carries 8 marks."
          : "Attempt any 4 of the following. ",
      note_disabled: true,
      marks: "24",
      marks_disabled: false,

      questions:
        class_id == "2"
          ? [
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
            ]
          : [
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "",
                marks: "",
              },
            ],
    },
  };
};

export const physicsKPKPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Four circles are given with every question. Only fill the circle with correct answer. ",
      note_disabled: true,
      objective_marks: "12",
      objective_time: "20 Minutes",
      subjective_time: "2 Hours 40 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "Time allowed Section B and Section C is 2 Hours and 45 Minutes.",
      note_disabled: true,
      marks: "32",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note:
            class_id == "2"
              ? "Attempt any EIGHT of the following short questions. Each question carries 4 marks."
              : "Briefly attempt any Eight of following short questions, each carry 4 marks",
          ui_style: "bullet_roman",
          marks: "32",
        },
      ],
    },
    long_question: {
      note:
        class_id == "2"
          ? "Attempt any three of the following questions."
          : "Attempt any THREE of the following questions, each carry equal marks",
      note_disabled: true,
      marks: "21",
      marks_disabled: false,

      questions:
        class_id == "2"
          ? [
              {
                id: uuid(),
                note: "",
                ui_style: "bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "bullet",
                marks: "4+3",
              },
            ]
          : [
              {
                id: uuid(),
                note: "",
                ui_style: "single_line_bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "single_line_bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "single_line_bullet",
                marks: "4+3",
              },
              {
                id: uuid(),
                note: "",
                ui_style: "single_line_bullet",
                marks: "4+3",
              },
            ],
    },
  };
};

export const chemistryKPKPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "There are three sections of this paper, i.e. Section A, B and C. Attempt Section-A on this sheet and return it to the Superintendent within the given time. No marks will be awarded for Cutting, Erasing or Overwriting. Marks of identification will lead to UFM case. Mobile Phones, etc. are not allowed in the Examination Hall.",
      note_disabled: false,
      objective_marks: "12",
      objective_time: "15 Minutes",
      subjective_time: "2 Hours and 45 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "Time allowed Section B and Section C is 2 Hours and 45 Minutes.",
      note_disabled: true,
      marks: "32",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer any EIGHT of the following. Each part carries FOUR marks.",
          ui_style: "bullet",
          marks: "32",
        },
      ],
    },
    long_question: {
      note: "Attempt any THREE questions. All questions carry equal marks.",
      note_disabled: true,
      marks: "21",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(4+3)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(4+3)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(4+3)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(4+3)",
        },
      ],
    },
  };
};

export const englishKPKPaper9 = () => {
  return {
    instruction: {
      objective_note:
        "Attempt all questions of Section A by filling the corresponding bubble on the MCQs RESPONSE SHEET. It is mandatory to return the attempted MCQs sheet to the Superintendent within given time.",
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2:40 Hours",
      subjective_marks: "60",
    },
    short_question: {
      note: "Sections 'B' and 'C' comprises pages no. 3-4 and questions therein are to be answered on the separately provided answer book. Answer all the questions from section 'B' and section 'C'. Use supplementary answer sheet i.e. sheet B if required. Write your answers neatly and legibly.",
      marks: "36",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Answer any SIX of the following parts. Each part carries equal marks.",
          ui_style: "bullet",
          marks: "(18)",
        },
        {
          id: uuid(),
          note: "Paraphrase ONE of the following stanzas.",
          ui_style: "rtl_row_2_bullet",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Read the stanza carefully and answer the questions given at the end.",
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "Read the passage carefully and answer the questions given at the end.",
          marks: "(05)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "24",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Translate the following into Urdu",
          ui_style: "bullet",
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "(04)",
        },
        {
          id: uuid(),
          note: "Translate the following sentences into English. ",
          ui_style: "rtl_row_2_bullet",
          marks: "(08)",
        },
      ],
    },
  };
};

export const englishKPKPaper10 = () => {
  return {
    instruction: {
      objective_note:
        "There are THREE sections in this paper i.e. Section A, B and C.",
      note_disabled: true,
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2:40 Hours",
      subjective_marks: "60",
    },
    short_question: {
      note: "Sections 'B' and 'C' comprises pages no. 3-5 and questions therein are to be answered on the separately provided answer book. Answer all the questions from section 'B' and section 'C'. Use supplementary answer sheet i.e. sheet B if required. Write your answers neatly and legibly.",
      marks: "36",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Read the following passage carefully and answer any 06 of the following questions.",
          ui_style: "bullet",
          marks: "(6x3=18)",
        },
        {
          id: uuid(),
          note: "Paraphrase the following lines.",
          ui_style: "rtl_row_2_bullet",
          marks: "(05)",
        },
        {
          id: uuid(),
          note: "Read the following stanza carefully and answer the questions given at the end:",
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "Change the narration.",
          marks: "(02)",
          ui_style: "bullet_roman"
        },
        {
          id: uuid(),
          note: "Do as directed.",
          marks: "(03)",
          ui_style: "bullet_roman",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "24",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Translate the following paragraph in Urdu. ",
          ui_style: "bullet",
          marks: "(04)",
        },
        {
          id: uuid(),
          note: "Translate the following sentences in Urdu. ",
          ui_style: "bullet",
          marks: "(04)",
        },
        {
          id: uuid(),
          note: "Write an essay of 250 words on any one of the following topics.",
          ui_style: "bullet",
          marks: "(10)",
        },
        {
          id: uuid(),
          note: "",
          marks: "",
        },
        {
          id: uuid(),
          note: "Translate the following sentences into English.",
          marks: "(06)",
          ui_style: "bullet"
        },
      ],
    },
  };
};

// <!!!!!!!!!!!!!!!!!!!!!!!!!!!!  Fedral Board paper data !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>

export const physicsFedaralPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Q.1	Fill in the relevant bubble against each question according to curriculum:  ",
      note_disabled: true,
      objective_marks: "12",
      objective_time: "20 Minutes",
      subjective_time: "2 Hours 40 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "Answer any eleven parts from Section 'B' and attempt any two questions from Section 'C' on the separately provided answer book. Write your answers neatly and legibly.",
      note_disabled: true,
      objective_time: "2:45 hours",
      marks: "53",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(03)",
        },
      ],
    },
    long_question: {
      note:
        class_id == "2"
          ? "Attempt any three of the following questions."
          : "Note:	Attempt any TWO questions. All questions carry equal marks.",
      note_disabled: true,
      marks: "(2 × 10 = 20)",
      marks_disabled: false,

      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "05",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "05",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "05",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "05",
        },
      ],
    },
  };
};

export const chemistryFedralPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "There are three sections of this paper, i.e. Section A, B and C. Attempt Section-A on this sheet and return it to the Superintendent within the given time. No marks will be awarded for Cutting, Erasing or Overwriting. Marks of identification will lead to UFM case. Mobile Phones, etc. are not allowed in the Examination Hall.",
      note_disabled: false,
      objective_marks: "12",
      objective_time: "20 Minutes",
      subjective_time: "2 Hours and 45 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "",
      note_disabled: true,
      marks: "(2 x 10 = 20)",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
      ],
    },
    long_question: {
      note: "Attempt any TWO questions. All questions carry equal marks.",
      note_disabled: true,
      marks: "(2 x 10 = 20)",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "bullet",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet",
          marks: "6",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet",
          marks: "4",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet",
          marks: "4",
        },
      ],
    },
  };
};

export const biologyFedaralPaper = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Q.1	Fill in the relevant bubble against each question according to curriculum:  ",
      note_disabled: true,
      objective_marks: "12",
      objective_time: "15 Minutes",
      subjective_time: "2 Hours 40 Minutes",
      subjective_marks: "53",
    },
    short_question: {
      note: "",
      note_disabled: true,
      objective_time: "2:45 hours",
      marks: "53",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          marks: "3",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
        {
          id: uuid(),
          note: "",
          marks: "3",
          ui_style: "bullet_roman",
        },
      ],
    },
    long_question: {
      note: "Attempt all questions. Marks of each question are given along with each question.",
      note_disabled: true,
      marks: "(4×5=20)",
      marks_disabled: false,

      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "5",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "5",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "5",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "single_line_bullet",
          marks: "5",
        },
      ],
    },
  };
};

export const mathFedralPaper9 = (class_id) => {
  return {
    instruction: {
      objective_note:
        "Q1	Fill the relevant bubble against each question. Each part carries one mark. ",
      note_disabled: true,
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2 Hours 40 Minutes",
      subjective_marks: "60",
    },
    short_question: {
      note: "Note:	Attempt any nine parts from Section 'B' and any three questions from Section 'C' on the separately provided answer book. Use supplementary answer sheet i.e. Sheet-B if required. Write your answers neatly and legibly. Log book and graph paper will be provided on demand. ",
      note_disabled: true,
      marks: "(9 × 4 = 36)",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "bullet_roman",
          marks: "(4)",
        },
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "(3 × 8 = 24)",
      marks_disabled: false,

      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "8",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "8",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "",
          marks: "8",
        },
      ],
    },
  };
};

export const englishFedralPaper9 = () => {
  return {
    instruction: {
      objective_note:
        "Attempt all questions of Section A by filling the corresponding bubble on the MCQs RESPONSE SHEET. It is mandatory to return the attempted MCQs sheet to the Superintendent within given time.",
      objective_marks: "15",
      objective_time: "20 Minutes",
      subjective_time: "2:40 Hours",
      subjective_marks: "60",
    },
    short_question: {
      note: "",
      marks: "36",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "Read the following passage carefully and answer any FIVE questions including Question No. (i) appended to it.",
          ui_style: "q_two_part_a",
          marks: "(6 + 4x3=18)",
        },
        // {
        //   id: uuid(),
        //   note: "Questions",
        //   ui_style: "q_two_part_a",
        //   marks: "(5+1=6)",
        // },
        {
          id: uuid(),
          note: "Paraphrase any ONE of the following stanzas:",
          ui_style: "q_three_part_a",
          marks: "(03)",
        },
        {
          id: uuid(),
          note: "Read the following stanza carefully and answer the questions given at the end:",
          ui_style: "q_three_part_a",
          marks: "(06)",
        },
        {
          id: uuid(),
          note: "Fill any four of the blanks in the passage cited below using appropriate transitional devices given in the bar.",
          ui_style: "q_four_part_a",
          marks: "(04)",
        },
        {
          id: uuid(),
          note: "Attempt any six of the given sentences as per the direction given in the bracket.",
          ui_style: "q_four_part_a",
          marks: "(06)",
        },
        
        {
          id: uuid(),
          note: `Punctuate the following para/line(s).\n A candidate is supposed to spot at least six mistakes of punctuation and rewrite the para/lines after correcting the mistakes.`,
          ui_style: "q_four_part_a",
          marks: "(03)",
        }
        
      ],
    },
    long_question: {
      note: "",
      note_disabled: true,
      marks: "20",
      marks_disabled: false,
      questions: [
        {
          id: uuid(),
          note: "",
          ui_style: "bullet",
          marks: "(08)",
        },
        {
          id: uuid(),
          note: "Write a paragraph of about 80 to 100 words on any one of the following: ",
          ui_style: "single_line_bullet",
          marks: "(04+04)",
        },
        {
          id: uuid(),
          note: "",
          ui_style: "rtl_row_2_bullet",
          marks: "(08)",
        },
      ],
    },
  };
};

//<!!!!!!!!!!!!!!!!!!!!!!!!!!! Bords !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>

const getSindhBoardPaperData = ({ board_id, subject_id, class_id }) => {
  const class_9 = "1";
  const class_10 = "2";

  const isChemistry = ["6"].includes(`${subject_id}`);
  const isBio = ["7"].includes(`${subject_id}`);
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isPhysic = ["13"].includes(`${subject_id}`);
  const isComputerStudies = ["18"].includes(`${subject_id}`);
  const isPakStudies = ["16"].includes(`${subject_id}`);
  const isUrdu = ["17"].includes(`${subject_id}`);
  const isIslamiyat = ["19"].includes(`${subject_id}`);
  const isSindhi = ["4"].includes(`${subject_id}`);
  const isTadrees = ["20"].includes(`${subject_id}`);

  if (isEnglish) {
    return class_id == class_10 ? englishSindhPaper10() : englishSindhPaper9();
  }
  if (isComputerStudies) {
    return computerStudiesSindhPaper(class_id);
  }
  if (isPakStudies) {
    return pakistanStudiesSindhPaper(class_id);
  }
  if (isUrdu) {
    return class_id == class_10 ? urduSindhPaper10(class_id) : urduSindhPaper9(class_id);
  }
  if (isIslamiyat || isTadrees) {
    return islamiyatSindhaper(class_id);
  }
  if (isSindhi) {
    return sindhiSindhaper(class_id);
  }

  if (isMath) {
    return class_id == class_10
      ? defaultSindhPaper({
          paper_marks: "75",
          paper_time: "03 hours",
          objective_marks: "15",
          objective_time: "20 minutes",
          subjective_time: "02 hours 40 mintues",
          subjective_marks: "60",
          short_question_0_note:
            "Answer any Six (06) questions from this section. All questions carry equal marks",
          short_question_marks: "30",
          long_question_0_note:
            "Answer any Three (03) questions from this section. all questions carry equal marks",
          long_question_marks: "30",
        })
      : defaultSindhPaper({
          paper_marks: "75",
          paper_time: "03 hours",
          objective_marks: "15",
          objective_time: "20 minutes",
          subjective_time: "02 hours 40 mintues",
          subjective_marks: "60",
          short_question_0_note:
            "Answer any Six (06) questions from this section. All questions carry equal marks",
          short_question_marks: "30",
          long_question_0_note:
            "Answer any Three (03) questions from this section. all questions carry equal marks",
          long_question_marks: "30",
        });
  }

  if (isChemistry) {
    return class_id == class_10
      ? defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        })
      : defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        });
  }

  if (isBio) {
    return class_id == class_10
      ? defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        })
      : defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        });
  }

  if (isPhysic) {
    return class_id == class_10
      ? defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        })
      : defaultSindhPaper({
          paper_marks: "60",
          paper_time: "03 hours",
          objective_marks: "12",
          objective_time: "15 minutes",
          subjective_time: "02 hours 45 mintues",
          subjective_marks: "48",
          short_question_0_note:
            "Answer any Eight (08) questions from this section. Each question carries 03 marks",
          short_question_marks: "24",
          long_question_0_note:
            "Answer any Four (04) questions from this section. Each question carries 06 marks",
          long_question_marks: "24",
        });
  }

  return defaultSindhPaper();
};

const getPunjabBoardPaperData = ({ board_id, subject_id, class_id }) => {
  const class_9 = "1";
  const class_10 = "2";
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isPakStudies = ["16"].includes(`${subject_id}`);
  const isComputerStudies = ["18"].includes(`${subject_id}`);
  const isUrdu = ["17"].includes(`${subject_id}`);
  const isTadrees = ["20"].includes(`${subject_id}`);
  const isIslamiyat = ["19"].includes(`${subject_id}`);

  if (isEnglish) {
    return class_id == class_10 ? englishPaper10() : englishPaper9();
  }
  if (isPakStudies) {
    return pakStudiesPunjabPaper9(class_id);
  }
  if (isComputerStudies) {
    return computerStudiesPunjabPaper(class_id);
  }
  if (isTadrees) {
    return tadreesQuranPunjabaper(class_id);
  }
  if (isUrdu) {
    return class_id == class_10
      ? urduPunjabaper10(class_id)
      : urduPunjabaper9(class_id);
  }
  if (isMath) {
    return mathPaper();
  }
  if (isIslamiyat) {
    return islPunjabPaper(class_id);
  }
  return defaultPaper();
};

const getKPKBoardPaperData = ({ board_id, subject_id, class_id }) => {
  const class_9 = "1";
  const class_10 = "2";
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isBiology = ["7"].includes(`${subject_id}`);
  const isPhysics = ["13"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);

  if (isEnglish) {
    return class_id == class_10 ? englishKPKPaper10() : englishKPKPaper9();
  }
  if (isBiology) {
    return class_id == class_10 ? biologyKPKPaper10() : biologyKPKPaper10();
  }
  if (isPhysics) {
    return physicsKPKPaper(class_id);
  }
  if (isChemistry) {
    return chemistryKPKPaper(class_id);
  }

  if (isMath) {
    return mathKPKPaper9(class_id);
  }

  return defaultPaper();
};

const getFedaralBoardPaperData = ({ board_id, subject_id, class_id }) => {
  const class_9 = "1";
  const class_10 = "2";
  const isEnglish = ["1", "11"].includes(`${subject_id}`);
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isBiology = ["7"].includes(`${subject_id}`);
  const isPhysics = ["13"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);

  if (isEnglish) {
    return englishFedralPaper9();
  }
  if (isBiology) {
    return biologyFedaralPaper(class_id);
  }
  if (isPhysics) {
    return physicsFedaralPaper(class_id);
  }
  if (isChemistry) {
    return chemistryFedralPaper(class_id);
  }

  if (isMath) {
    return mathFedralPaper9(class_id);
  }
 

  return defaultPaper();
};

export const getPaperData = ({ board_id, subject_id, class_id }) => {
  if (board_id == 4) {
    return getSindhBoardPaperData({ board_id, subject_id, class_id });
  } else if (board_id == 1) {
    return getKPKBoardPaperData({ board_id, subject_id, class_id });
  } else if (board_id == 3) {
    return getFedaralBoardPaperData({ board_id, subject_id, class_id });
  } else {
    return getPunjabBoardPaperData({ board_id, subject_id, class_id });
  }
};
