import React from "react";

const CustomPagination = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  return (
    <nav aria-label="Table navigation">
      <ul className="pagination justify-content-end">
        <li>
          <div className="input-group">
            <div className="input-group-prepend">
              <label
                className="input-group-text"
                style={{ padding: "4.5px 8px" }}
              >
                Rows per page
              </label>
            </div>
            <select
              className="custom-select"
              style={{ padding: "4.5px 1.75rem 4.5px 1rem", height: "auto" }}
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
            >
              {[5, 10, 25, 100].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </li>

        <li className={"page-item disabled"}>
          <a className="page-link">
            {startIndex + 1}-{endIndex} 0f {count}
          </a>
        </li>

        <li
          className={page === 0 ? "page-item disabled" : "page-item"}
          onClick={() => onPageChange(page === 0 ? 0 : page - 1)}
          style={{ cursor: "pointer" }}
        >
          <a className="page-link" tabIndex="-1" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        {/* {items} */}
        <li
          className={
            page === Math.ceil(count / rowsPerPage) - 1
              ? "page-item disabled"
              : "page-item"
          }
          onClick={() =>
            onPageChange(
              page === Math.ceil(count / rowsPerPage) - 1 ? page : page + 1
            )
          }
          style={{ cursor: "pointer" }}
        >
          <a className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default CustomPagination;